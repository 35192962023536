import Chart from 'chart.js';
import Heatmap from '../Heatmap.vue';

export default {
    props: ['stats', 'video'],
    components: {Heatmap},
    data() {
        // Do not remove anything from chartData or chartOptions, as messy as this might look,
        // these variables are required by chart.js.
        return {
            blocks: {
                percetageSequence: [],
                duration: [],
                blockSize: '0px',
                heatColors: {
                    0: '#F6F7F9',
                    11: '#E5FF9F',
                    30: '#FCE77C',
                    50: '#FFDC71',
                    70: '#F7AA72',
                    90: '#F28D44'
                }
            },
            chartData: {
                labels: [],
                datasets: [{
                    label: 'Test',
                    backgroundColor: null,
                    pointBackgroundColor: 'white',
                    borderWidth: 2,
                    borderColor: '#00ACDC',
                    data: [],
                }]
            },
            chartOptions: {
                responsive: false,
                maintainAspectRatio: true,
                animation: {
                    easing: 'easeInOutQuad',
                    duration: 10
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                            fontColor: '#21455e94',
                            fontSize: 12
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            max: 125,
                            min: 0,
                            stepSize: 25,
                            callback: function(value, index, values) {
                                if (value == 0 || value == 125) return '';
                                return `${value}%`;
                            },
                            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                            fontColor: '#21455e94',
                            fontSize: 12
                        },
                    }]
                },
                elements: {
                    line: {
                        tension: 0.4,
                    }
                },
                legend: {
                    display: false
                },
                point: {
                    backgroundColor: 'white'
                },
                tooltips: {
                    displayColors: false,
                    titleFontFamily: 'Open Sans',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#00ACDC',
                    borderWidth: 1,
                    titleFontColor: '#21455ec9',
                    bodyFontColor: '#21455ec9',
                    caretSize: 5,
                    cornerRadius: 5,
                    bodyFontSize: 15,
                    xPadding: 0,
                    yPadding: 15,
                    callbacks: {
                        label: (item, data) => {
                            return this.populateTooltips(item.index);
                        },
                    },
                }
            }
        }
    },
    mounted() {},
    methods: {
        populateTooltips(index)
        {
            let stats = this.stats,
                selected = stats[index.toString()];
            if (!selected) return '';
            return [
                `          ${selected.duration}          `,
                '______________________',
                '',
                `          ${selected.viewed} Viewed          `,
                '',
                `          ${selected.dropped} Dropped          `
            ];
            
        },
        prepareCanvas()
        {
            var canvas      = document.getElementById('chart'),
                chart       = canvas.getContext('2d'),
                gradient    = chart.createLinearGradient(0, 0, 0, 400);

            gradient.addColorStop(0, '#95E8FF');
            gradient.addColorStop(0.5, '#95e8ffaf');
            gradient.addColorStop(1, '#FFFFFF00');
            this.chartData.datasets[0].backgroundColor = gradient;
            this.adjustSize(canvas);
            this.showChart(chart);
        },
        showChart(chart)
        {
            var chartInstance = new Chart(chart, {
                type: 'line',
                data: this.chartData,
                options: this.chartOptions
            });
        },
        populateStats(stats)
        {
            for (let i in stats) {
                let stat = stats[i],
                    percentage = 0;
                if (stat.dropped == 0) {
                    if (stat.viewed == 0) percentage = '0';
                    else percentage = 100;
                }
                else if (stat.viewed == 0) percentage = '0';
                else percentage = stat.viewed * 100 / (stat.viewed + stat.dropped);
                if (percentage) {
                    this.chartData.datasets[0].data.push(percentage);
                    this.blocks.percetageSequence.push(percentage); // Easy, one variable for heatmap.
                    this.blocks.duration.push(stat.duration);
                    this.chartData.labels.push('');
                }
            }
            this.adjustSize();
        },
        adjustSize(canvas = null)
        {
            if (canvas) {
                canvas.width = 722;
                canvas.height = 408;
            } else {
                if (!this.blocks.percetageSequence.length) return false;
                let width = 671 / this.blocks.percetageSequence.length;
                this.blocks.blockSize = `${width}px`;
            }
        }
    },
    watch: {
        stats(n, o)
        {
            this.populateStats(n);
            this.prepareCanvas();
        }
    }
}