<template>
    <el-container class="justify-content-center">
        <el-card
            class="box-card view-summary m-top-4"
            :class="{'three-column': filterOptions.checkedVideos.length === 1}"
        >
            <div slot="header" class="clearfix">
                <span class="header-bold">Interaction Summary</span>
            </div>
            <summary-section :show-column="(filterOptions.checkedVideos.length === 1 ? 3 : 4)">
                <template
                    v-slot:first-head
                    
                >
                    <el-popover
                        ref="playRatePop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_250x62"
                        content="Play Rate"
                    ></el-popover>PLAY RATE
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:playRatePop
                        v-if="help_hover"
                        alt
                        @mouseout="help_hover = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:playRatePop
                        v-else
                        alt
                        @mouseover="help_hover = true"
                    />
                </template>
                <template
                    v-slot:first-body
                    
                >
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.play_rate.today"
                        :filter-val-yesterday="filterData.summary.play_rate.yesterday"
                        :filter-val-curr="filterData.summary.play_rate.total"
                        :filter-val-prev="filterData.summary.play_rate.prev"
                        summary-unit="%"
                    ></summary-body>
                </template>
                <template v-slot:second-head>
                    <el-popover
                        ref="clickThroughPop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_250x62"
                        content="This is the total number"
                    ></el-popover>CLICKTHROUGH
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:clickThroughPop
                        v-if="help_hover1"
                        alt
                        @mouseout="help_hover1 = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:clickThroughPop
                        v-else
                        alt
                        @mouseover="help_hover1 = true"
                    />
                </template>
                <template v-slot:second-body>
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.click_through.today"
                        :filter-val-yesterday="filterData.summary.click_through.yesterday"
                        :filter-val-curr="filterData.summary.click_through.total"
                        :filter-val-prev="filterData.summary.click_through.prev"
                        :sub-show="true"
                        :sub-parent="filterData.summary.views_count"
                    ></summary-body>
                </template>
                <template v-slot:third-head>
                    <el-popover
                        ref="emailCapturePop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_250x62"
                        content="This is the total number"
                    ></el-popover>EMAIL CAPTURE
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:emailCapturePop
                        v-if="help_hover1"
                        alt
                        @mouseout="help_hover1 = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:emailCapturePop
                        v-else
                        alt
                        @mouseover="help_hover1 = true"
                    />
                </template>
                <template v-slot:third-body>
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.email_capture.today"
                        :filter-val-yesterday="filterData.summary.email_capture.yesterday"
                        :filter-val-curr="filterData.summary.email_capture.total"
                        :filter-val-prev="filterData.summary.email_capture.prev"
                        :sub-show="true"
                        :sub-parent="filterData.summary.views_count"
                    ></summary-body>
                </template>
                <template v-slot:fourth-head>
                    <el-popover
                        ref="relatedVideos"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_250x62"
                        content="This is the total number"
                    ></el-popover>RELATED VIDEOS
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:relatedVideos
                        v-if="help_hover1"
                        alt
                        @mouseout="help_hover1 = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:relatedVideos
                        v-else
                        alt
                        @mouseover="help_hover1 = true"
                    />
                </template>
                <template v-slot:fourth-body>
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.related_videos.today"
                        :filter-val-yesterday="filterData.summary.related_videos.yesterday"
                        :filter-val-curr="filterData.summary.related_videos.total"
                        :filter-val-prev="filterData.summary.related_videos.prev"
                    ></summary-body>
                </template>
            </summary-section>
        </el-card>
    </el-container>
</template>

<script>
import SummarySection from "./SummarySection";
import SummaryBody from "./SummaryBody";

export default {
    name: "InteractionSummary",
    components: { SummaryBody, SummarySection },
    props: {
        filterOptions: Object,
        filterData: Object,
        dateLabel: String
    },
    data: () => ({
        help_hover: false,
        help_hover1: false,
        help_hover2: false,
        help_hover3: false
    }),
    mounted() {
        this.$nextTick(() => {});
    }
};
</script>

<style scoped lang="less">
.view-summary {
    width: 100%;

    .header-bold {
        font-weight: 500;
    }

    &.three-column {
        width: 80%;
    }
}
</style>
