<template>
    <div class="analytics-audience" id="analytics-audience">
        <audience-summary :date-label="date_range_label"
                          :filter-options="filterOptions"
                          :filter-data="filterData"></audience-summary>

        <el-row class="m-top-4 video-lists">
            <el-col :span="12" :xs="24">
                <top-countries :date-label="date_range_label" :top-videos="filterData.top_countries" :mobile-check="mobileCheck"/>
            </el-col>
            <el-col :span="12" :xs="24">
                <top-countries
                    progress-color="#00CAFF"
                    :date-label="date_range_label"
                    :top-videos="filterData.top_domains"
                    :mobile-check="mobileCheck" title-str="Source URL"></top-countries>
            </el-col>
        </el-row>

        <view-locations :filter-data="filterData"></view-locations>

        <el-row class="m-top-4">
            <el-col :span="12" :xs="24">
                <audience-device
                    :date-label="date_range_label"
                    :data-ary="[
                        {title: 'Desktop', percentage: getPercent(filterData.views_devices.desktop, filterData.views_devices.total)},
                        {title: 'Mobile', percentage: getPercent(filterData.views_devices.mobile, filterData.views_devices.total)},
                        {title: 'Tablet', percentage: getPercent(filterData.views_devices.tablet, filterData.views_devices.total)}
                    ]"
                    :total-views="filterData.views_devices.total"
                    :limitViews="5"
                ></audience-device>
            </el-col>
            <el-col :span="12" :xs="24">
                <audience-device
                    title-str="Gender"
                    :date-label="date_range_label"
                    :data-ary="[
                        {title: 'Male', percentage: getPercent(filterData.gender.male, filterData.gender.total)},
                        {title: 'Female', percentage: getPercent(filterData.gender.female, filterData.gender.total)},
                    ]"
                    :total-views="filterData.gender.total"
                    :limitViews="50"
                ></audience-device>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import ViewLocations from "./Items/ViewLocations";
    import AudienceSummary from "./Items/AudienceSummary";
    import TopCountries from "./Items/TopCountries";
    import AudienceDevice from "./Items/AudienceDevice";

    export default {
        name: "Audience",
        components: {AudienceDevice, TopCountries, AudienceSummary, ViewLocations},
        props: {
            filterOptions: Object,
            filterData: Object,
            mobileCheck: Boolean
        },
        data: () => ({
            date_range_label: 'Last 30 Days',
        }),
        mounted() {
            this.$nextTick(() => {
                this.date_range_label = this.$store.state.date_range_label[this.filterOptions.timeline_type];
            });
        },
        methods: {
            getPercent(c, p) {
                if (p) {
                    return Math.round(((c / p) * 100) * 100) / 100;
                } else {
                    return 0;
                }
            }
        }
    }
</script>

<style scoped>

</style>
