<template>
    <el-card class="box-card social-media-area">
        <div slot="header" class="clearfix">
            <span class="header-bold">Social Media</span>
        </div>
        <summary-section>
            <template v-slot:first-head>
                <el-popover
                    ref="sViewsPop"
                    placement="top"
                    trigger="hover"
                    popper-class="custom_popover pop_top_250x62"
                    content="This is the total number of people who viewed your videos."
                ></el-popover>VIEWS
                <img
                    src="../../../assets/img/analytics/noun-info-hover.svg"
                    v-popover:sViewsPop
                    v-if="help_hover"
                    alt
                    @mouseout="help_hover = false"
                />
                <img
                    src="../../../assets/img/analytics/noun-info.svg"
                    v-popover:sViewsPop
                    v-else
                    alt
                    @mouseover="help_hover = true"
                />
            </template>
            <template v-slot:first-body>
                <summary-body
                    :date-label="date_range_label"
                    :filter-val-today="1802"
                    :filter-val-curr="24485"
                    :show-percent="false"
                ></summary-body>
            </template>

            <template v-slot:second-head>
                <el-popover
                    ref="likesPop"
                    placement="top"
                    trigger="hover"
                    popper-class="custom_popover pop_top_250x62"
                    content="This is the total number of people who likes your videos."
                ></el-popover>LIKES
                <img
                    src="../../../assets/img/analytics/noun-info-hover.svg"
                    v-popover:likesPop
                    v-if="help_hover1"
                    alt
                    @mouseout="help_hover1 = false"
                />
                <img
                    src="../../../assets/img/analytics/noun-info.svg"
                    v-popover:likesPop
                    v-else
                    alt
                    @mouseover="help_hover1 = true"
                />
            </template>
            <template v-slot:second-body>
                <summary-body
                    :date-label="date_range_label"
                    :filter-val-today="749"
                    :filter-val-curr="16482"
                    :show-percent="false"
                ></summary-body>
            </template>

            <template v-slot:third-head>
                <el-popover
                    ref="commentsPop"
                    placement="top"
                    trigger="hover"
                    popper-class="custom_popover pop_top_250x62"
                    content="This is the total number of people who commented your videos."
                ></el-popover>COMMENTS
                <img
                    src="../../../assets/img/analytics/noun-info-hover.svg"
                    v-popover:commentsPop
                    v-if="help_hover2"
                    alt
                    @mouseout="help_hover2 = false"
                />
                <img
                    src="../../../assets/img/analytics/noun-info.svg"
                    v-popover:commentsPop
                    v-else
                    alt
                    @mouseover="help_hover2 = true"
                />
            </template>
            <template v-slot:third-body>
                <summary-body
                    :date-label="date_range_label"
                    :filter-val-today="363"
                    :filter-val-curr="5498"
                    :show-percent="false"
                ></summary-body>
            </template>

            <template v-slot:fourth-head>
                <el-popover
                    ref="sharesPop"
                    placement="top"
                    trigger="hover"
                    popper-class="custom_popover pop_top_250x62"
                    content="This is the total number of people who shared your videos."
                ></el-popover>SHARES
                <img
                    src="../../../assets/img/analytics/noun-info-hover.svg"
                    v-popover:sharesPop
                    v-if="help_hover3"
                    alt
                    @mouseout="help_hover3 = false"
                />
                <img
                    src="../../../assets/img/analytics/noun-info.svg"
                    v-popover:sharesPop
                    v-else
                    alt
                    @mouseover="help_hover3 = true"
                />
            </template>
            <template v-slot:fourth-body>
                <summary-body
                    :date-label="date_range_label"
                    :filter-val-today="568"
                    :filter-val-curr="6873"
                    :show-percent="false"
                ></summary-body>
            </template>
        </summary-section>

        <!-- <el-row :gutter="40" class="m-top-4">
            <el-col :span="6" :xs="24">
                <social-card
                    :statistics-count="{
                    index: 'facebook',
                    views: 5384,
                    likes: 2483,
                    comments: 204,
                    shares: 76,
                }"
                    :style-str="{'background-color': '#F7F9FC', 'color': '#3B5998'}"
                ></social-card>
            </el-col>
            <el-col :span="6" :xs="24">
                <social-card
                    :statistics-count="{
                    index: 'twitter',
                    views: 1485,
                    likes: 940,
                    comments: 137,
                    shares: 34,
                }"
                    :style-str="{'background-color': '#F5FCFF', 'color': '#00BAF5'}"
                ></social-card>
            </el-col>
            <el-col :span="6" :xs="24">
                <social-card
                    :statistics-count="{
                    index: 'youtube',
                    views: 2402,
                    likes: 846,
                    comments: 98,
                    shares: 25,
                }"
                    :style-str="{'background-color': '#FFF5F5', 'color': '#FB0000'}"
                ></social-card>
            </el-col>
            <el-col :span="6" :xs="24">
                <social-card
                    :statistics-count="{
                    index: 'linkedin',
                    views: 0,
                    likes: 234,
                    comments: 85,
                    shares: 15,
                }"
                    :style-str="{'background-color': '#F5FAFC', 'color': '#0077B7'}"
                ></social-card>
            </el-col>
        </el-row> -->
    </el-card>
</template>

<script>
import SummaryBody from "./SummaryBody";
import SummarySection from "./SummarySection";
import SocialCard from "./SocialCard";

export default {
    name: "SocialMedia",
    props: {
        filterOptions: Object,
        filterData: Object,
        mobileCheck: Boolean
    },
    components: { SocialCard, SummarySection, SummaryBody },
    data: () => ({
        date_range_label: "Last 30 Days",
        help_hover: false,
        help_hover1: false,
        help_hover2: false,
        help_hover3: false
    }),
    mounted() {
        // this.$nextTick(() => {
        //     this.date_range_label = this.$store.state.date_range_label[
        //         this.filterOptions.timeline_type
        //     ];
        // });
    }
};
</script>

<style scoped lang="less">
.social-media-area {
    .header-bold {
        font-weight: 500;
    }
}
</style>
