<template>
    <el-card class="box-card view-summary m-top-4">
        <div slot="header" class="clearfix">
            <span class="header-summary size-20 main_color">View Summary </span>
        </div>
        <summary-section class="summary-section">
            <template v-slot:first-head>
                <el-popover ref="impressionsPop" placement="top" trigger="hover" popper-class="custom_popover popover_box_style pop_top_240x62"
                            content="This is the total number of people who visited your videos."></el-popover>
                <span class="head-text size-16 main_color">IMPRESSIONS</span>
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:impressionsPop v-if="help_hover" alt @mouseout="help_hover = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:impressionsPop v-else alt @mouseover="help_hover = true"/>
            </template>
            <template v-slot:first-body>
                <summary-body :date-label="dateLabel" :range-date="rangeDate"
                              :filter-val-today="filterData.summary.impression.today"
                              :filter-val-yesterday="filterData.summary.impression.yesterday"
                              :filter-val-curr="filterData.summary.impression.total"
                              :filter-val-prev="filterData.summary.impression.prev"></summary-body>
            </template>

            <template v-slot:second-head>
                <el-popover ref="viewsPop" placement="top" trigger="hover" popper-class="custom_popover popover_box_style pop_top_330x62"
                            content="This is the total number of people who clicked the play button and watch your videos."></el-popover>
                <span class="head-text size-16 main_color">VIEWS</span>
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:viewsPop v-if="help_hover1" alt @mouseout="help_hover1 = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:viewsPop v-else alt @mouseover="help_hover1 = true"/>
            </template>
            <template v-slot:second-body>
                <summary-body :date-label="dateLabel" :range-date="rangeDate"
                              :filter-val-today="filterData.summary.views.today"
                              :filter-val-yesterday="filterData.summary.views.yesterday"
                              :filter-val-curr="filterData.summary.views.total"
                              :filter-val-prev="filterData.summary.views.prev"></summary-body>
            </template>

            <template v-slot:third-head>
                <el-popover ref="watchPop" placement="top" trigger="hover" popper-class="custom_popover popover_box_style pop_top_220x62"
                            content="This is the total number of watch time."></el-popover>
                <span class="head-text size-16 main_color">WATCH TIME</span> <span class="head-text__minutes">(Minutes)</span>
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:watchPop v-if="help_hover2" alt @mouseout="help_hover2 = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:watchPop v-else alt @mouseover="help_hover2 = true"/>
            </template>
            <template v-slot:third-body>
                <summary-body :date-label="dateLabel" :range-date="rangeDate"
                              :filter-val-today="Math.round(filterData.summary.watch.today / 60)"
                              :filter-val-yesterday="Math.round(filterData.summary.watch.yesterday / 60)"
                              :filter-val-curr="Math.round(filterData.summary.watch.total / 60)"
                              :filter-val-prev="Math.round(filterData.summary.watch.prev / 60) "></summary-body>
            </template>

            <template v-slot:fourth-head>
                <el-popover ref="devicePopover" placement="top" trigger="hover" popper-class="custom_popover popover_box_style pop_top_220x62"
                            content="This is the total number of watched devices."></el-popover>
                <span class="head-text size-16 main_color">DEVICES</span> <span class="head-text__minutes">(Last 30-day Avg.)</span>
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:devicePopover v-if="help_hover3" alt @mouseout="help_hover3 = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:devicePopover v-else alt @mouseover="help_hover3 = true"/>
            </template>
            <template v-slot:fourth-body>
                <el-container>
                    <div class="m-top-4 w30 flex-column-end">
                        <div class="sum-number text-center" v-if="filterData.summary.devices.desktop !== 0">
                            {{ getInDeStatus(filterData.summary.views.total, filterData.summary.devices.desktop) }}%
                        </div>
                        <div class="sum-number text-center main_color" v-else>0%</div>
                        <div class="m-top-2 text-center main_color size-16">Desktop</div>
                    </div>
                    <div class="m-top-4 w20 flex-column-end">
                        <div class="sum-number text-center" v-if="filterData.summary.devices.desktop !== 0">
                            {{ getInDeStatus(filterData.summary.views.total, filterData.summary.devices.mobile) }}%
                        </div>
                        <div class="sum-number text-center main_color" v-else>0%</div>
                        <div class="m-top-2 text-center main_color size-16">Phone</div>
                    </div>
                    <div class="m-top-4 w30 flex-column-end">
                        <div class="sum-number text-center" v-if="filterData.summary.devices.desktop !== 0">
                            {{ getInDeStatus(filterData.summary.views.total, filterData.summary.devices.tablet) }}%
                        </div>
                        <div class="sum-number text-center main_color" v-else>0%</div>
                        <div class="m-top-2 text-center main_color size-16">Tablet</div>
                    </div>
                </el-container>
            </template>
        </summary-section>
    </el-card>
</template>

<script>
    import SummaryBody from "./SummaryBody";
    import SummarySection from "./SummarySection";

    export default {
        name: "ViewSummary",
        components: {SummarySection, SummaryBody},
        props: {
            dateLabel: {
                type: String,
                default: 'Last 30 Days'
            },
            filterData: Object,
            rangeDate: String
        },
        data: () => ({
            help_hover: false,
            help_hover1: false,
            help_hover2: false,
            help_hover3: false
        }),
        created() {
            console.log('test');
        },
        methods: {
            getInDeStatus(totalViews, deviceViews) {
                if (totalViews === 0) {
                    return 0;
                }
                return Math.round(((deviceViews / totalViews) * 100) * 100) / 100;
            }
        }
    }
</script>

<style lang="less" scoped>
    .header-summary {
        font-weight: 500;
        font-size: 16px;
        margin-left: 20px;
         @media (max-width: 414px){
            margin-left: 0
        }
    }

    .summary-section {
        margin: 0px 20px 0px 20px;
    }

    .head-text {
        letter-spacing: 0.1rem;

        &__minutes {
            color: #9e9e9e;
            margin-left: 5px;
        }
    }

    .sum-number {
        font-size: 17px;
        margin-bottom: 5px;
    }
    .flex-column-end{
        display: flex;
        flex-direction: column;
        align-items: end
    }
</style>
