<template>
    <div class="social-card" :style="styleStr">
        <div class="head-logo">
            <img src="../../../assets/img/analytics/facebook.svg" alt="" v-if="statisticsCount.index === 'facebook'"/>
            <img src="../../../assets/img/analytics/twitter.svg" alt="" v-if="statisticsCount.index === 'twitter'"/>
            <img src="../../../assets/img/analytics/youtube.svg" alt="" v-if="statisticsCount.index === 'youtube'"/>
            <img src="../../../assets/img/analytics/linkedin.svg" alt="" v-if="statisticsCount.index === 'linkedin'"/>
        </div>
        <div class="statistics-content">
            <div class="align-items-center">
                <div class="w-60">Views</div>
                <div class="w-40" v-html="formatNumber(statisticsCount.views)"></div>
            </div>
            <div class="align-items-center">
                <div class="w-60">Likes</div>
                <div class="w-40" v-html="formatNumber(statisticsCount.likes)"></div>
            </div>
            <div class="align-items-center">
                <div class="w-60">Comments</div>
                <div class="w-40" v-html="formatNumber(statisticsCount.comments)"></div>
            </div>
            <div class="align-items-center">
                <div class="w-60">Shares</div>
                <div class="w-40" v-html="formatNumber(statisticsCount.shares)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SocialCard",
        props: {
            styleStr: Object,
            statisticsCount: Object
        },
        methods: {
            formatNumber(num) {
                if (num === 0) {
                    return '<svg xmlns="http://www.w3.org/2000/svg" width="17.4" height="1.4" viewBox="0 0 17.4 1.4">\n' +
                        '<line id="Line_430" data-name="Line 430" x2="16" transform="translate(0.7 0.7)" fill="none" stroke="#0077b7" stroke-linecap="round" stroke-width="1.4"/>\n' +
                        '</svg>';
                }
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
        }
    }
</script>

<style scoped lang="less">
    .social-card {
        padding: 19px;
        border-radius: 10px;
        font-size: 13px;

        * {
            font-size: 13px;
        }

        .head-logo {
            width: 100%;
            height: 23px;
        }

        .statistics-content {
            > div {
                margin-top: 10px;

                .w-60 {
                    opacity: 0.9;
                }

                .w-40 {
                    text-align: right;
                }
            }
        }

        @media (max-width: 414px){
            margin-bottom: 20px
        }
    }
</style>
