import {Bar, mixins, Line } from 'vue-chartjs'

const {reactiveProp} = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: ['options'],
    data: () => ({
        gradient: null
    }),
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
}
