<template>
    <el-row :gutter="40" class="m-top-4 d-flex flex-wrap top-realtime-video">
        <el-col :span="12" :xs="24" class="px-xs-0">
            <top-videos :date-label="dateLabel" :top-videos="topVideos" :mobile-check="mobileCheck" :show-view-all="showViewAll"></top-videos>
        </el-col>
        <el-col :span="12" :xs="24" class="px-xs-0">
            <top-rt-videos :top-videos="top24Videos" :mobile-check="mobileCheck" :total-charts="top24Charts" :show-view-all="showViewAll"></top-rt-videos>
        </el-col>
    </el-row>
</template>

<script>
    import TopVideos from "./TopVideos";
    import TopRtVideos from "./TopRtVideos";

    export default {
        name: "VideosHistory",
        components: {TopRtVideos, TopVideos},
        props: {
            dateLabel: String,
            topVideos: Array,
            mobileCheck: Boolean,
            top24Videos: Array,
            top24Charts: Object,
            showViewAll: {
                type: Boolean,
                default: true
            }
        }
    }
</script>
<style lang="less">
    .top-realtime-video{
        .el-card{
            height: 100%;
        }

        @media (max-width: 414px){
            margin-left: 0 !important;
            margin-right: 0 !important;
            .px-xs-0{
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
</style>
