<template>
    <el-container class="summary-body">
        <div class="m-top-4">
            <div class="sum-number size-22 main_color" v-if="timeShow">{{ getDurF(filterValToday) }}</div>
            <div class="sum-number size-22 main_color" v-else>
                {{ formatNumber(filterValToday) }}{{summaryUnit}}
                <el-tooltip
                    class="sub-value item"
                    effect="dark"
                    v-if="subShow"
                    placement="bottom"
                    content="As a percentage of views"
                >
                    <span>{{ getInDeStatus(subParent.today, filterValToday) }}%</span>
                </el-tooltip>
            </div>

            <el-tooltip
                v-if="showPercent"
                class="item"
                effect="dark"
                :content="`Today: ${timeShow ? getDurF(filterValYesterday) : formatNumber(filterValYesterday)}`"
                placement="bottom"
            >
                <div
                    class="m-top-2"
                    :class="{
                         'increase-pro': increaseProYesterday,
                         'decrease-pro': decreaseProYesterday,
                         'normal-pro': normalProYesterday,
                         }"
                >
                    <img
                        src="../../../assets/img/analytics/up.svg"
                        alt
                        v-if="increaseProYesterday"
                        class="arrow-margin"
                    />
                    <img
                        src="../../../assets/img/analytics/down.svg"
                        alt
                        v-else-if="decreaseProYesterday"
                        class="arrow-margin"
                    />
                    {{
                    (filterValYesterday === 0 && filterValToday === 0) ? 0 : (
                    (100 - getInDeStatus(filterValYesterday, filterValToday)) > 0 ?
                    Math.round((100 - getInDeStatus(filterValYesterday, filterValToday)) * 100) / 100 :
                    getInDeStatus(filterValYesterday, filterValToday)
                    )
                    }}%
                </div>
            </el-tooltip>
            <div class="m-top-2 size-16 main_color">Today</div>
        </div>
        <div class="m-top-4" style="margin-left: 50px;">
            <div class="sum-number" v-if="timeShow">{{ getDurF(filterValCurr) }}</div>
            <div class="sum-number" v-else>
                {{ formatNumber(filterValCurr) }}{{summaryUnit}}
                <el-tooltip
                    class="sub-value item"
                    effect="dark"
                    v-if="subShow"
                    placement="bottom"
                    content="As a percentage of views"
                >
                    <span>{{ getInDeStatus(subParent.total, filterValCurr) }}%</span>
                </el-tooltip>
            </div>
            <el-tooltip
                v-if="showPercent"
                class="item"
                effect="dark"
                :content="`${dateLabel !== 'Custom Range' ? dateLabel.replace('Last', 'Previous') : rangeDate}: ${timeShow ? getDurF(filterValPrev) : formatNumber(filterValPrev)}`"
                placement="bottom"
            >
                <div
                    class="m-top-2 cursor-pointer"
                    :class="{
                         'increase-pro': increaseProPrev,
                         'decrease-pro': decreaseProPrev,
                         'normal-pro': normalProPrev,
                         'normal': !increaseProPrev && !decreaseProPrev && !normalProPrev
                         }"
                >
                    <img
                        src="../../../assets/img/analytics/up.svg"
                        alt
                        v-if="increaseProPrev"
                        class="arrow-margin"
                    />
                    <img
                        src="../../../assets/img/analytics/down.svg"
                        alt
                        v-else-if="decreaseProPrev"
                        class="arrow-margin"
                    />
                    {{
                    (filterValPrev === 0 && filterValCurr === 0) ? 0 : (
                    (100 - getInDeStatus(filterValPrev, filterValCurr)) > 0 ?
                    Math.round((100 - getInDeStatus(filterValPrev, filterValCurr)) * 100) / 100 :
                    getInDeStatus(filterValPrev, filterValCurr)
                    )
                    }}%
                </div>
            </el-tooltip>

            <div class="m-top-2 size-16 main_color">{{ dateLabel }}</div>
        </div>
    </el-container>
</template>

<script>
import { SUM_BODY_NUMBER_REG } from "../../../constants";

export default {
    name: "SummaryBody",
    props: {
        filterValCurr: Number,
        filterValToday: Number,
        filterValPrev: {
            type: Number,
            default: 0
        },
        filterValYesterday: {
            type: Number,
            default: 0
        },
        dateLabel: String,
        rangeDate: String,
        showPercent: {
            type: Boolean,
            default: true
        },
        summaryUnit: {
            type: String,
            default: ""
        },
        timeShow: {
            type: Boolean,
            default: false
        },
        subShow: {
            type: Boolean,
            default: false
        },
        subParent: {
            type: Object,
            default: null
        }
    },
    data: function() {
        return {
            increaseProYesterday: false,
            decreaseProYesterday: false,
            normalProYesterday: false,
            increaseProPrev: false,
            decreaseProPrev: false,
            normalProPrev: false
        };
    },
    mounted() {
        this.increaseProYesterday =
            100 -
                this.getInDeStatus(
                    this.filterValYesterday,
                    this.filterValToday
                ) <
            0;

        this.decreaseProYesterday =
            100 -
                this.getInDeStatus(
                    this.filterValYesterday,
                    this.filterValToday
                ) >
                0 &&
            (this.filterValYesterday !== 0 || this.filterValToday !== 0);

        this.normalProYesterday =
            this.filterValYesterday === 0 || this.filterValToday === 0;

        this.increaseProPrev =
            100 - this.getInDeStatus(this.filterValPrev, this.filterValCurr) <
                0 ||
            this.getInDeStatus(this.filterValPrev, this.filterValCurr) === 100;

        this.decreaseProPrev =
            100 - this.getInDeStatus(this.filterValPrev, this.filterValCurr) >
                0 &&
            (this.filterValPrev !== 0 || this.filterValCurr !== 0);

        this.normalProPrev =
            100 - this.getInDeStatus(this.filterValPrev, this.filterValCurr) ===
                0 &&
            (this.filterValPrev === 0 || this.filterValCurr === 0);
    },
    methods: {
        /**
         * percentage calc
         * @param p - parent
         * @param c - child
         * @returns {number}
         */
        getInDeStatus(p, c) {
            if (p === undefined || p === 0) {
                if (c === undefined || c === 0) {
                    return 0;
                } else {
                    return 100;
                }
            }

            return Math.round((c / p) * 100 * 100) / 100;
        },
        formatNumber(num) {
            return num.toString().replace(SUM_BODY_NUMBER_REG, "$1,");
        },
        getDurF(s) {
            let d = new Date(s * 60 * 1000);
            let secs = d.getUTCSeconds();
            let minutes = d.getUTCMinutes();

            if (d.getUTCHours() > 0) {
                return (
                    d.getUTCHours() +
                    ":" +
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            } else {
                return (
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            }
        }
    }
};
</script>

<style lang="less" scoped>
.summary-body {
    display: flex;
    align-items: center;
    min-height: 104px;

    div {
        font-size: 13px;
        text-align: left;

        &.text-center {
            text-align: center;
        }

        &.w30 {
            width: 30%;
        }

        div {
            width: fit-content;

            &.sum-number {
                font-size: 20px;
                margin-bottom: 5px;

                .sub-value {
                    font-size: 12px;
                    cursor: pointer;
                }
            }

            &.increase-pro,
            &.decrease-pro,
            &.normal-pro {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .arrow-margin {
                    margin-right: 5px;
                }
            }

            &.normal {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .arrow-margin {
                    margin-right: 5px;
                }
            }

            &.increase-pro {
                color: #0ad688;
            }

            &.decrease-pro {
                color: var(--color-red);
            }
        }
    }
}
</style>
