<template>
	<div class='d-flex'>
        <div v-for='(percent, index) in percetageSequence'
            :key='index'>
            <div
                class="blocks"
                :style='{
                    "width": width,
                    "height": height,
                    "background": heatColors[
                        Object.keys(heatColors)
                        .filter(key => key <= percent)
                        .reverse()[0]
                    ]
                }'
            >
                <span class='watched-percentage'>{{ Math.round(percent) }}% Watched</span>
                <div v-if='events' class="event" @mouseover='selectedTip = index' @mouseleave='selectedTip = null'>
                	<div 
                		class='floaty'
                		v-if='getEvent(index) && selectedTip == index'
 						:class='getEvent(index) ? "down-arrow" : ""'
 						:style='{"left": floatyPosition}'>
                		{{ getEvent(index) }}
	                </div>
	                <div v-if='getEvent(index)'>
		                <img class='map-icon smallArrow' src="../../src/assets/img/contacts/viewer-interaction.svg">
			            <div class="event-line" :class='getEvent(index) ? "centered-event" : ""'></div>
		          	</div>
                </div>
            </div>
            <span v-if='duration && index % durationGap == 0'>{{ duration[index] }}</span>
        </div>
    </div>
</template>

<script>
	import $ from 'jquery';

	export default {
		props: ['percetageSequence', 'width', 'height', 'heatColors', 'duration', 'events'],
		data: () => ({
			selectedTip: null,
			floatyPosition: null,
			durationGap: null
		}),
		methods: {
			getEvent(index)
			{
        		return this.events[
            		Object.keys(this.events)
            		.filter(key => {
            			if (key == 'before' && index == 0)
            				return true;
            			else if (key == 'after' && index == percetageSequence.length - 1)
            				return true;
            			else return key == index;
            			return false;
            		})
        		];
			},
		},
		mounted()
		{
			let width = this.width.replace('px', '');
			this.floatyPosition = `-${Math.round(75)}px`;
			this.durationGap = Math.round(this.percetageSequence.length / 16);
		}
	}
</script>

<style lang='less' scoped>
	.blocks {
		position: relative;
	}
	.blocks:hover {
	    position: relative;
	}
	.blocks .watched-percentage {
	    display: none;
	}
	.blocks:hover .watched-percentage {
		background: #011A22;
		color: #fff;
	    padding: 0 10px;
	    opacity: 0.6;
	    border-radius: 2px;
	    display: block;
	    z-index: 100;
	    left: 0px;
	    margin: 10px;
	    width: max-content;
	    position: absolute;
	    top: 10px;
	    text-decoration: none;
	}

	.event {
		.centered-event {
			position: absolute;
		    left: 0;
		    margin: auto;
		    border: 1px dashed #011A22;
		    box-shadow: 0px 3px 6px #00000029;
		    width: 1px;
		    height: 68px;
		    cursor: pointer;
		}
		.map-icon {
		    position: absolute;
		    left: -10.5px;
		    margin: auto;
		    margin-top: -28px;
		    cursor: pointer;
		}
	}
		.floaty {
			position: absolute;
		    top: -60px;
		    right: 0;
		    width: 150px;
		    text-align: center;
		    background: #011A22;
		    color: #fff;
		    border-radius: 5px;
		    padding: 1px 6px;
		    font-family: 'Helvetica Neue';
		    cursor: pointer;
		}

		.down-arrow:after {
		    content: "";
		    position: absolute;
		    left: 0;
		    right: 0;
		    margin: 0 auto;
		    width: 0;
		    margin-top: 20px;
		    height: 0;
		    border-top: 8px solid #1b1e2f;
		    border-left: 8px solid transparent;
		    border-right: 8px solid transparent;
		}
</style>