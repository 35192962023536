var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticClass:"summary-body"},[_c('div',{staticClass:"m-top-4"},[(_vm.timeShow)?_c('div',{staticClass:"sum-number size-22 main_color"},[_vm._v(_vm._s(_vm.getDurF(_vm.filterValToday)))]):_c('div',{staticClass:"sum-number size-22 main_color"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.filterValToday))+_vm._s(_vm.summaryUnit)+" "),(_vm.subShow)?_c('el-tooltip',{staticClass:"sub-value item",attrs:{"effect":"dark","placement":"bottom","content":"As a percentage of views"}},[_c('span',[_vm._v(_vm._s(_vm.getInDeStatus(_vm.subParent.today, _vm.filterValToday))+"%")])]):_vm._e()],1),(_vm.showPercent)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":("Today: " + (_vm.timeShow ? _vm.getDurF(_vm.filterValYesterday) : _vm.formatNumber(_vm.filterValYesterday))),"placement":"bottom"}},[_c('div',{staticClass:"m-top-2",class:{
                     'increase-pro': _vm.increaseProYesterday,
                     'decrease-pro': _vm.decreaseProYesterday,
                     'normal-pro': _vm.normalProYesterday,
                     }},[(_vm.increaseProYesterday)?_c('img',{staticClass:"arrow-margin",attrs:{"src":require("../../../assets/img/analytics/up.svg"),"alt":""}}):(_vm.decreaseProYesterday)?_c('img',{staticClass:"arrow-margin",attrs:{"src":require("../../../assets/img/analytics/down.svg"),"alt":""}}):_vm._e(),_vm._v(" "+_vm._s((_vm.filterValYesterday === 0 && _vm.filterValToday === 0) ? 0 : ( (100 - _vm.getInDeStatus(_vm.filterValYesterday, _vm.filterValToday)) > 0 ? Math.round((100 - _vm.getInDeStatus(_vm.filterValYesterday, _vm.filterValToday)) * 100) / 100 : _vm.getInDeStatus(_vm.filterValYesterday, _vm.filterValToday) ))+"% ")])]):_vm._e(),_c('div',{staticClass:"m-top-2 size-16 main_color"},[_vm._v("Today")])],1),_c('div',{staticClass:"m-top-4",staticStyle:{"margin-left":"50px"}},[(_vm.timeShow)?_c('div',{staticClass:"sum-number"},[_vm._v(_vm._s(_vm.getDurF(_vm.filterValCurr)))]):_c('div',{staticClass:"sum-number"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.filterValCurr))+_vm._s(_vm.summaryUnit)+" "),(_vm.subShow)?_c('el-tooltip',{staticClass:"sub-value item",attrs:{"effect":"dark","placement":"bottom","content":"As a percentage of views"}},[_c('span',[_vm._v(_vm._s(_vm.getInDeStatus(_vm.subParent.total, _vm.filterValCurr))+"%")])]):_vm._e()],1),(_vm.showPercent)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":((_vm.dateLabel !== 'Custom Range' ? _vm.dateLabel.replace('Last', 'Previous') : _vm.rangeDate) + ": " + (_vm.timeShow ? _vm.getDurF(_vm.filterValPrev) : _vm.formatNumber(_vm.filterValPrev))),"placement":"bottom"}},[_c('div',{staticClass:"m-top-2 cursor-pointer",class:{
                     'increase-pro': _vm.increaseProPrev,
                     'decrease-pro': _vm.decreaseProPrev,
                     'normal-pro': _vm.normalProPrev,
                     'normal': !_vm.increaseProPrev && !_vm.decreaseProPrev && !_vm.normalProPrev
                     }},[(_vm.increaseProPrev)?_c('img',{staticClass:"arrow-margin",attrs:{"src":require("../../../assets/img/analytics/up.svg"),"alt":""}}):(_vm.decreaseProPrev)?_c('img',{staticClass:"arrow-margin",attrs:{"src":require("../../../assets/img/analytics/down.svg"),"alt":""}}):_vm._e(),_vm._v(" "+_vm._s((_vm.filterValPrev === 0 && _vm.filterValCurr === 0) ? 0 : ( (100 - _vm.getInDeStatus(_vm.filterValPrev, _vm.filterValCurr)) > 0 ? Math.round((100 - _vm.getInDeStatus(_vm.filterValPrev, _vm.filterValCurr)) * 100) / 100 : _vm.getInDeStatus(_vm.filterValPrev, _vm.filterValCurr) ))+"% ")])]):_vm._e(),_c('div',{staticClass:"m-top-2 size-16 main_color"},[_vm._v(_vm._s(_vm.dateLabel))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }