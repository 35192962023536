<template>
    <el-container class="justify-content-center">
        <el-card class="box-card view-trend m-top-4">
            <div class="clearfix headers">
                <div class="header-bold header-trend">Engagement Trend</div>
                <div class="header-bold header-summary">Engagement Summary</div>
            </div>
            <div class="trend-container">
                <div class="chart-column">
                    <div class="chart-percentages" v-if='video'>
                        <Chart :stats='statistics' :video='video'></Chart>
                    </div>
                </div>
                <div class="statistic-column">
                    <el-popover
                        ref="avgEngagementPop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_240x62"
                        :content="popoverContent.avgEngagemnt"
                    ></el-popover>AVG. ENGAGEMENT
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:avgEngagementPop
                        v-if="help_hover"
                        alt
                        @mouseout="help_hover = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:avgEngagementPop
                        v-else
                        alt
                        @mouseover="help_hover = true"
                    />

                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.avg_engagement.today"
                        :filter-val-yesterday="filterData.summary.avg_engagement.yesterday"
                        :filter-val-curr="filterData.summary.avg_engagement.total"
                        :filter-val-prev="filterData.summary.avg_engagement.prev"
                        summary-unit="%"
                        class="summary-body-margin-bottom"
                    ></summary-body>

                    <el-popover
                        ref="watchPop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_220x62"
                        :content="popoverContent.watchTime"
                    ></el-popover>WATCH TIME
                    <span class="engagement-minutes">(Minutes)</span>
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:watchPop
                        v-if="help_hover1"
                        alt
                        @mouseout="help_hover1 = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:watchPop
                        v-else
                        alt
                        @mouseover="help_hover1 = true"
                    />
<!-- Math.round(filterData.summary.watch.yesterday / 60) * 100) / 100 -->
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="Math.round(filterData.summary.watch.today / 60)"
                        :filter-val-yesterday="Math.round(filterData.summary.watch.yesterday / 60)"
                        :filter-val-curr="Math.round(filterData.summary.watch.total / 60)"
                        :filter-val-prev="Math.round(filterData.summary.watch.prev / 60)"
                        class="summary-body-margin-bottom"
                    ></summary-body>

                    <el-popover
                        ref="avgDurationPop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_250x62"
                        :content="popoverContent.avgViewDuration"
                    ></el-popover>AVG. VIEW DURATION
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:avgDurationPop
                        v-if="help_hover2"
                        alt
                        @mouseout="help_hover2 = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:avgDurationPop
                        v-else
                        alt
                        @mouseover="help_hover2 = true"
                    />

                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.avg_duration.today"
                        :filter-val-yesterday="filterData.summary.avg_duration.yesterday"
                        :filter-val-curr="filterData.summary.avg_duration.total"
                        :filter-val-prev="filterData.summary.avg_duration.prev"
                        :time-show="true"
                    ></summary-body>
                </div>
            </div>
        </el-card>
    </el-container>
</template>

<script>
import { ENGAGEMENT_SUMMARY_POPOVER_CONTENT } from "../../../constants";
import SummarySection from "./SummarySection";
import SummaryBody from "./SummaryBody";
import WatchHistoryHeatmap from "../../../utils/watchHistoryHeatmap/WatchHistoryHeatmap";
import LineChart from "../../../utils/Chart/LineChart";
import { customTooltip } from "../../../utils/Chart/Tooltip";
import Chart from '../../StatisticsChart/Chart.vue';

export default {
    name: "EngagementTrendForOne",
    components: { SummaryBody, SummarySection, WatchHistoryHeatmap, LineChart, Chart },
    props: {
        filterData: Object,
        dateLabel: String,
        chartData: {
            type: Object,
            default: {
                labels: [],
                datasets: []
            }
        },
        videoId: null
    },
    data: () => ({
        history_array: [],
        selContact: {
            id: 2
        },
        help_hover: false,
        help_hover1: false,
        help_hover2: false,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            events: ["mousemove"], // this is needed, otherwise onHover is not fired
            onHover: (event, chartElement) => {
                event.target.style.cursor = chartElement[0]
                    ? "pointer"
                    : "default";
            },
            tooltips: {
                enabled: false,
                mode: "point",
                intersect: true,
                custom: customTooltip
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawTicks: false,
                            color: "#EAF0F4",
                            zeroLineColor: "#EAF0F4"
                        },
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            padding: 5
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawTicks: false,
                            color: "transparent"
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 20,
                            lineHeight: "normal",
                            maxRotation: 0,
                            padding: 5
                        }
                    }
                ]
            }
        },
        popoverContent: ENGAGEMENT_SUMMARY_POPOVER_CONTENT,
        video: null,
        linechartstyle: null,
        statistics: null
    }),
    mounted()
    {
        this.getVideo();
    },
    methods: {
        getWatchHistory() {
            const post_data = {
                url: `/contacts/get-contact-watch-history`,
                data: {
                    id: this.selContact.id,
                    query: this.dateRange,
                    video_id: this.video.id
                }
            };
            this.$store.dispatch("post", { ...post_data }).then(r => {
                this.history_array = r.data.slice(0,1);
            });
        },
        getSingleVideoStyle()
        {
            this.linechartstyle = {
                'height': '378px',
                'width': '90%',
                'background':'url('+this.video.thumbnail+') right top',
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                'background-size': '95% 98%',
                'position': 'absolute',
                'left': 0,
                'margin': 'auto'
            };
        },
        getVideo()
        {
            this.axios.get(`video/show?id=${this.videoId}`).then(r => {
                this.video = r.data.video;
                this.getVideoStats(this.video.id);
            });
        },
        getVideoStats(id)
        {
            let dateRange = this.getDate(this.dateLabel);
            this.axios.get(`single-video-stats?id=${id}&days=${dateRange}`).then(r => {
                this.statistics = r.data;
            });
        },
        getDate(label)
        {
            switch (label) {
                case 'Yesterday':
                    return 1;
                break;
                case 'Last 7 Days':
                    return 7;
                break;
                case 'Last 30 Days':
                    return 30;
                break;
                case 'Last 90 Days':
                    return 90;
                break;
                case 'Last 365 Days':
                    return 365;
                break;
                case 'Last Year':
                    return 'last_year';
                break;
                case 'This Year':
                    return 'this_year';
                break;
                case 'All Time':
                    return null;
                break;
                case 'Last Month':
                    return 'last_month';
                break;
                case 'This Month':
                    return 'this_month';
                break;
            }
        }
    },
    watch: {
        video(n, o)
        {
            this.getSingleVideoStyle();
            // this.getWatchHistory();
        }
    }
};
</script>

<style scoped lang="less">
.view-trend {
    width: 100%;
    display: flex;
    flex-direction: column;

    .headers {
        display: flex;
        margin-bottom: 15px;

        .header-trend {
            width: 75%;
        }
    }

    .trend-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .chart-column {
            width: 75%;
            display: flex;
            flex-direction: column;
            position: relative;
            .chart {
                height: 400px;
            }
        }

        .statistic-column {
            width: 25%;

            .summary-body-margin-bottom {
                margin-bottom: 32px;
            }
        }
    }

    .header-bold {
        font-weight: 500;
    }

    &.three-column {
        width: 80%;
    }

    .engagement-minutes {
        color: rgba(33, 69, 94, 0.7);
        margin-left: 5px;
    }
}
</style>
