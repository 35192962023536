<template>
    <div class="real-time-bar-chart">
        <bar-chart :chart-data="chartData" :options="options"></bar-chart>
    </div>
</template>

<script>
    import BarChart from "../../../utils/Chart/BarChart";
    import { customTooltip } from "../../../utils/Chart/Tooltip";

    export default {
        name: "RealTimeBar",
        components: {BarChart},
        props: {
            chartData: {
                type: Object,
                default: {}
            }
        },
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                    mode: "point",
                    intersect: true,
                    custom: customTooltip
                },
                scales: {
                    yAxes: [{
                        position: 'right',
                        gridLines: {
                            drawTicks: false,
                            color: "#C9EDF8",
                            zeroLineColor: "#EAF0F4",
                        },
                        ticks: {
                            beginAtZero: true,
                            stepSize: 100,
                            min: 0,
                            padding: 5
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            color: 'transparent'
                        },
                        ticks: {
                            display: false
                        },
                    }]
                }
            },
        }),
    }
</script>

<style scoped lang="less">
    .real-time-bar-chart {
        width: 100%;
        height: 100px;

        div {
            height: 100px;
        }
    }
</style>
