<template>
    <el-container class="justify-content-center">
        <el-card class="box-card view-summary m-top-4">
            <div slot="header" class="clearfix">
                <span>Audience Summary</span>
            </div>

            <summary-section :show-column="3">

                <template v-slot:second-head>
                    <el-popover ref="uniqueViewsPop" placement="top" trigger="hover" popper-class="custom_popover pop_top_250x62"
                                content="This is the total number of unique views."></el-popover>
                    UNIQUE VIEWS
                    <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:uniqueViewsPop v-if="help_hover" alt @mouseout="help_hover = false"/>
                    <img src="../../../assets/img/analytics/noun-info.svg" v-popover:uniqueViewsPop v-else alt @mouseover="help_hover = true"/>
                </template>
                <template v-slot:second-body>
                    <summary-body :date-label="dateLabel"
                                  :filter-val-today="filterData.summary.unique_views.today"
                                  :filter-val-yesterday="filterData.summary.unique_views.yesterday"
                                  :filter-val-curr="filterData.summary.unique_views.total"
                                  :filter-val-prev="filterData.summary.unique_views.prev"></summary-body>
                </template>

                <template v-slot:third-head>
                    <el-popover ref="avgViewPerViewerPop" placement="top" trigger="hover" popper-class="custom_popover pop_top_250x62"
                                content="This is the total number of avg view per viewers."></el-popover>
                    AVG. VIEW PER VIEWER
                    <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:avgViewPerViewerPop v-if="help_hover1" alt @mouseout="help_hover1 = false"/>
                    <img src="../../../assets/img/analytics/noun-info.svg" v-popover:avgViewPerViewerPop v-else alt @mouseover="help_hover1 = true"/>
                </template>
                <template v-slot:third-body>
                    <summary-body :date-label="dateLabel"
                                  :filter-val-today="filterData.summary.view_per_viewer.today"
                                  :filter-val-yesterday="filterData.summary.view_per_viewer.yesterday"
                                  :filter-val-curr="filterData.summary.view_per_viewer.total"
                                  :filter-val-prev="filterData.summary.view_per_viewer.prev"></summary-body>
                </template>

                <template v-slot:fourth-head>
                    <el-popover ref="contactsPop" placement="top" trigger="hover" popper-class="custom_popover pop_top_250x62"
                                content="This is the total number of the subscribed contacts."></el-popover>
                    CONTACTS
                    <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:contactsPop v-if="help_hover2" alt @mouseout="help_hover2 = false"/>
                    <img src="../../../assets/img/analytics/noun-info.svg" v-popover:contactsPop v-else alt @mouseover="help_hover2 = true"/>
                </template>
                <template v-slot:fourth-body>
                    <summary-body :date-label="dateLabel"
                                  :filter-val-today="filterData.summary.contacts.today"
                                  :filter-val-yesterday="filterData.summary.contacts.yesterday"
                                  :filter-val-curr="filterData.summary.contacts.total"
                                  :filter-val-prev="filterData.summary.contacts.prev"></summary-body>
                </template>

            </summary-section>

        </el-card>

    </el-container>

</template>

<script>
    import SummarySection from "./SummarySection";
    import SummaryBody from "./SummaryBody";

    export default {
        name: "AudienceSummary",
        components: {SummaryBody, SummarySection},
        props: {
            filterOptions: Object,
            filterData: Object,
            dateLabel: String
        },
        data: () => ({
            help_hover: false,
            help_hover1: false,
            help_hover2: false
        })
    }
</script>

<style scoped lang="less">
    .view-summary {
        width: 100%;

        &.three-column {
            width: 80%;
        }
    }
</style>
