<template>
    <el-card class="box-card view-trends m-top-4">
        <div slot="header" class="clearfix">
            <span class="header-trends">View Trends </span>
        </div>
        <div class="trend-chart-area">
            <line-chart :chart-data="chartData" :options="options"></line-chart>
        </div>
    </el-card>
</template>

<script>
    import LineChart from "../../../utils/Chart/LineChart";
    import { customTooltip } from '../../../utils/Chart/Tooltip';
    export default {
        name: "ViewTrends",
        components: {LineChart},
        props: {
            chartData: {
                type: Object,
                default: {}
            }
        },
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false,
                onResize: (chart)=>{
                    if(window.innerWidth <= 414){
                        chart.scales['x-axis-0'].options.ticks.maxTicksLimit = 2
                    }
                },
                events: ["mousemove"], // this is needed, otherwise onHover is not fired
                onHover: (event, chartElement) => {
                    event.target.style.cursor = chartElement[0]
                        ? "pointer"
                        : "default";
                },
                tooltips: {
                    enabled: false,
                    mode: 'index',
                    position: 'nearest',
                    custom: customTooltip
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 6,
                    },
                },
                scales: {
                    yAxes: [{
                        gridLines: {
                            drawTicks: false,
                            color: "#EAF0F4",
                            zeroLineColor: "#EAF0F4",
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 10,
                        },
                    }],
                    xAxes: [{
                        gridLines: {
                            drawTicks: false,
                            color: 'transparent'
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 7,
                            lineHeight: 'normal',
                            maxRotation: 0,
                            padding: 10,
                        }
                    }]
                }
            }
        })
    }
</script>

<style scoped lang="less">
    .trend-chart-area {
        height: 400px;
    }
    .header-trends {
        font-weight: 500;
        font-size: 20px;
        margin-left: 20px;
    }
</style>