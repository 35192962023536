import { render, staticRenderFns } from "./Chart.vue?vue&type=template&id=83bf1f1c&scoped=true&"
import script from "./Chart.js?vue&type=script&lang=js&"
export * from "./Chart.js?vue&type=script&lang=js&"
import style0 from "./Chart.less?vue&type=style&index=0&id=83bf1f1c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83bf1f1c",
  null
  
)

export default component.exports