<template>
    <div class="real-time-line-chart-area">
        <line-chart :chart-data="chartData" :options="options"></line-chart>
    </div>
</template>

<script>
    import LineChart from "../../../utils/Chart/LineChart";

    export default {
        name: "RealTimeArea",
        components: {LineChart},
        props: {
            chartData: {
                type: Object,
                default: {}
            }
        },
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    position: 'nearest',
                    mode: 'index',
                    intersect: false,
                    yPadding: 1,
                    xPadding: 2,
                    titleMarginBottom: 1,
                    caretSize: 0,
                    borderWidth: 0
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        gridLines: {
                            color: 'transparent'
                        },
                        ticks: {
                            display: false
                        },
                        display: false
                    }],
                    xAxes: [{
                        gridLines: {
                            color: 'transparent'
                        },
                        ticks: {
                            display: false
                        },
                        display: false
                    }]
                }
            }
        })
    }
</script>

<style scoped lang="less">
    .real-time-line-chart-area {
        width: 60%;
        height: 35px;

        div {
            height: 35px;
        }
    }
</style>
