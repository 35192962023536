<template>
    <el-card class="box-card m-top-4">
        <div slot="header" class="clearfix">
            <span>Locations</span>
        </div>
        <div class="world-locations">
            <div id="views-locations" class="views-locations"></div>
        </div>
    </el-card>
</template>

<script>
    window.$ = window.jQuery = require('jquery');

    require('jvectormap-next')($);
    require('../../../utils/jquery-jvectormap-world-mill.js');

    export default {
        name: "ViewLocations",
        props: {
            filterData: Object
        },
        data: () => ({
            markers: [],
        }),
        mounted() {
            this.filterData.views_locations.map((el, ind) => {
                this.markers[ind] = {
                    latLng: [el.latitude, el.longitude], name: el.city
                };
            });
            this.$nextTick(() => {
                this.initMap();
            })
        },
        methods: {
            initMap() {
                let el = $('#views-locations');
                el.empty();
                $(() => {
                    el.vectorMap({
                        map: 'world_mill',
                        hoverOpacity: 0.7,
                        hoverColor: false,
                        markerStyle: {
                            initial: {
                                fill: '#0078FF',
                                stroke: '#ffffff'
                            }
                        },
                        backgroundColor: '#011A22',
                        series: {
                            regions: [{
                                values: this.$store.state.gdpData,
                                scale: ['#316389', '#306b91'],
                                normalizeFunction: 'polynomial'
                            }]
                        },
                        markers: this.markers,
                        onMarkerTipShow: (event, label, index) => {
                            let divStr = '<div class="marker-area"><div class="marker-title">';
                            divStr += this.markers[index].name + '</div><div class="marker-views">Impressions: ';
                            divStr += `${this.filterData.views_locations[index].impression_count}</div><div class="marker-views">Views: `;
                            divStr += `${this.filterData.views_locations[index].view_count}</div></div>`;
                            label.html(divStr);
                        },
                    });
                });
            }
        }
    }
</script>

<style lang="less">
    @import "~jvectormap-next/jquery-jvectormap.css";

    .views-locations {
        width: 100%;
        min-height: 400px;

        .jvectormap-container {
            min-height: 400px;
        }
    }
</style>
