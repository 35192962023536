<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <span class="header-text size-20 main_color">{{ titleStr }}</span>
            <div class="size-14 main_color opacity-7">{{ subTitleStr }}: {{ dateLabel }}</div>
        </div>
        <div v-if="topVideos.length">
            <div v-for="(t_item, t_ind) in topVideos" :key="`top_videos_${t_ind}`" v-if="t_ind < 5" class="align-items-center video-history-lists m-top-4">
                <div class="history-thumbnail-part m-right-2" @mouseover="t_item.show = true" @mouseout="t_item.show = false">
                    <img alt="" :src="t_item.thumbnail" class="img-responsive"/>
                    <span class="video-duration" v-if="t_item.show">{{ getDurF(t_item.duration) }}</span>
                </div>
                <div class="history-chart-part" @mouseover="t_item.show = true" @mouseout="t_item.show = false">
                    <div class="video-title">
                        <a :href="$router.resolve(`/stage/videos/${t_item.video_key}`).href">{{ t_item.title }}</a>
                    </div>
                    <el-progress class="progress-bar" :stroke-width="15" :percentage="getPercent(t_item.view_count)" :format="format" :color="progressColor"></el-progress>
                    <div class="history-views-part" @mouseover="t_item.show = true" @mouseout="t_item.show = false">{{ formatNumber(t_item.view_count) }}</div>
                </div>     
            </div>
        </div>
        <div class="text-light-color m-top-4" v-else>
            Not videos to show this report
        </div>
        <div class="m-top-4 left-see-all" v-if="topVideos.length && topVideos.length > 4 && showViewAll">
            <a @click="showAllVideos = true">See All</a>
        </div>

        <!--  VIEW ALL Dialog -->
        <el-dialog
            v-if="showViewAll"
            :top="mobileCheck ? `10vh` : `30vh`"
            :visible.sync="showAllVideos"
            :width="mobileCheck ? `100%` : `700px`"
            center
            custom-class="pages-custom-dialog"
            :title="`${ titleStr }: ${dateLabel}`">
            <el-container class="video-list-modal">
                <div v-for="(t_item, t_ind) in topVideos" :key="`top_videos_${t_ind}`" class="align-items-center video-history-lists m-top-2">
                    <div class="history-thumbnail-part m-right-2" @mouseover="t_item.show = true" @mouseout="t_item.show = false">
                        <img alt="" :src="t_item.thumbnail" class="img-responsive"/>
                        <span class="video-duration" v-if="t_item.show">{{ getDurF(t_item.duration) }}</span>
                    </div>
                    <div class="history-chart-part m-right-2" @mouseover="t_item.show = true" @mouseout="t_item.show = false">
                        <div class="video-title">
                            <a :href="$router.resolve(`/stage/videos/${t_item.video_key}`).href">{{ t_item.title }}</a>
                        </div>
                        <el-progress :stroke-width="15" :percentage="getPercent(t_item.view_count)" :format="format" :color="progressColor"></el-progress>
                    </div>
                    <div class="history-views-part" @mouseover="t_item.show = true" @mouseout="t_item.show = false">{{ formatNumber(t_item.view_count) }}</div>
                </div>
            </el-container>
        </el-dialog>
    </el-card>
</template>

<script>
    export default {
        name: "TopVideos",
        props: {
            dateLabel: String,
            topVideos: Array,
            mobileCheck: Boolean,
            titleStr: {
                type: String,
                default: 'Top Videos'
            },
            subTitleStr: {
                type: String,
                default: 'Views'
            },
            progressColor: {
                type: String,
                default: '#5A51DD'
            },
            showViewAll: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            showAllVideos: false,
            top5Videos: new Array,
        }),
        mounted() {
            var self = this
            self.top5Videos = (this.topVideos).slice(0,5);
        },
        methods: {
            formatNumber(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },

            getDurF(s) {
                let d = new Date(s * 1000);
                let secs = d.getUTCSeconds();
                let minutes = d.getUTCMinutes();

                if (d.getUTCHours() > 0) {
                    return (d.getUTCHours() + ":" + (minutes >= 10 ? "" : "0") + minutes + ":" + (secs >= 10 ? "" : "0") + secs);
                } else {
                    return `${(minutes >= 10 ? "" : "0")}${minutes}:${(secs >= 10 ? "" : "0")}${secs}`;
                }
            },
            format(percentage) {
                return '';
            },
            getPercent(percent) {
                let value = 0;
                if (this.topVideos[0].view_count) {
                    value = Math.round((percent / this.topVideos[0].view_count) * 100);
                }
                value = parseInt(value); 
                if (value > 100) {
                    return 100;
                }
                return parseInt(value);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~element-ui/lib/theme-chalk/progress.css";

    .header-text {
        font-weight: 500;
    }

    .left-see-all {
        padding-bottom: 4.75rem;
    }

    .text-gray-color {
        color: rgb(122, 127, 129);
    }

    .video-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 200px !important;
    }
</style>
