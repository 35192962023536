<template>
    <el-card class="box-card device-gender-views">
        <div slot="header" class="clearfix">
            <span>{{ titleStr }}</span>
            <div class="small text-light-color">{{ subTitleStr }}: {{ dateLabel }}</div>
        </div>
        <div class="views-category" v-if="totalViews > limitViews">
            <div class="view-block"
                 v-for="(d_item, d_ind) in dataAry"
                 :key="`v-block${titleStr}-${d_ind}`"
                 :style="{'width': `${100 / dataAry.length}%`}">
                <div class="block-title">
                    {{ d_item.title }}
                </div>
                <div class="block-percent" :style="{'color': colorAry[d_ind]}">
                    {{ d_item.percentage }}%
                </div>
                <div class="block-bar" :style="{'background-color': colorAry[d_ind], 'width': `${d_item.percentage}%`}"></div>
            </div>
        </div>
        <div class="m-top-3" v-else>
            <div class="text-light-color">
                Not enough data to show this report
            </div>
        </div>
    </el-card>
</template>

<script>
    export default {
        name: "AudienceDevice",
        props: {
            dateLabel: String,
            dataAry: Array,
            totalViews: Number,
            limitViews: Number,
            titleStr: {
                type: String,
                default: 'Devices'
            },
            subTitleStr: {
                type: String,
                default: 'Views'
            },
        },
        data: () => ({
            colorAry: ['#5A51DE', '#2CCC71', '#F39C13']
        })
    }
</script>

<style scoped lang="less">
    .device-gender-views {
        .views-category {
            width: 100%;
            display: flex;
            align-items: center;

            .view-block {
                line-height: 30px;

                .block-bar {
                    height: 20px;
                }
            }

        }
    }
</style>
