<template>
    <el-container class="settings-area analytics-area" v-if="user" v-loading="loading">
        <FeaturesRestricted
            :section='"Analytics"'
            @desktopResolution='desktopResolution = $event'
            :maxWidth='"1024"'></FeaturesRestricted>
        <left-side :active-tab="activeTab" :class='!desktopResolution ? "blurred" : ""'></left-side>

        <el-container id="settings-container" v-if='desktopResolution'>
            <div class="settings-right-area">
                <right-header-footer class="header-items m-top-3">
                    <template v-slot:title>{{ rightTitle[activeTab] }}</template>
                </right-header-footer>

                <el-container class="settings-right-content">
                    <el-col :span="24">
                       <search-option></search-option>

                        <overview v-if="activeTab === 'overview' && filteredData"
                                :filter-options="filterQuery"
                                :filter-data="filteredData" :mobile-check="mobileCheck"></overview>

                        <engagement v-else-if="activeTab === 'engagement' && filteredData" :filter-options="filterQuery"
                                    :filter-data="filteredData" :mobile-check="mobileCheck"></engagement>

                        <audience v-else-if="activeTab === 'audience' && filteredData"
                                :filter-options="filterQuery"
                                    :filter-data="filteredData" :mobile-check="mobileCheck"></audience>
                    </el-col>
                   
                </el-container>
            </div>
        </el-container>
    </el-container>
</template>

<script>
    import LeftSide from "./Analytics/Layout/LeftSide";
    import Overview from "./Analytics/Overview";
    import Engagement from "./Analytics/Engagement";
    import Audience from "./Analytics/Audience";
    import SearchOption from "./Analytics/Items/SearchOption";
    import RightHeaderFooter from "./Analytics/Layout/RightHeaderFooter";
    import FeaturesRestricted from '../components/Errors/FeaturesRestricted';

    export default {
        name: "Analytics",
        components: {RightHeaderFooter, SearchOption, Audience, Engagement, Overview, LeftSide, FeaturesRestricted},
        props: {
            user: Object
        },
        computed: {
            mobileCheck: {
                get() {
                    let BC_onMobile = false;
                    if (window.screen.availWidth <= 414 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        BC_onMobile = true;
                    }

                    return BC_onMobile;
                }
            }
        },
        data: () => ({
            activeTab: "overview",
            rightTitle: {
                overview: 'Overview',
                engagement: 'Engagement',
                audience: 'Audience',
            },
            filterQuery: {
                checkedProjects: [],
                checkedVideos: [],
                start_date: '',
                end_date: '',
                timeline_type: '30',
                checkedCountries: [],
                checkedDomains: [],
                checkedDevices: [],
            },
            filteredData: null,
            loading: false,
            desktopResolution: true
        }),
        created() {
            // alert('created')
            //             this.eventListen();
            // this.setBasicValues();

            if (this.$route.params && this.$route.params.section) {
                this.activeTab = this.$route.params.section;
            }
        },
        beforeDestroy() {
            window.vEvent.stop("handle-analytics-menu-select", this.handleMenuSelect);
            window.vEvent.stop("set-analytics-search-query", this.setSearchOptions);
        },
        mounted() {
            this.eventListen();
            this.setBasicValues();
        },
        methods: {
            eventListen() {

                window.vEvent.listen("handle-analytics-menu-select", this.handleMenuSelect);
                window.vEvent.listen("set-analytics-search-query", this.setSearchOptions);
            },

            setBasicValues() {
                if (!this.desktopResolution) return false;
                let leftsectheight = window.document.getElementById("settings-left-side").clientHeight;
                let windowheight = window.innerHeight - window.document.getElementById("top-bar").clientHeight;
                if (leftsectheight < windowheight) {
                    window.document.getElementById("settings-left-side").style.minHeight = windowheight + "px";
                }
                this.$nextTick(() => {
                    let showVisitCheck = setInterval(() => {
                        if (this.user) {
                            this.$forceUpdate();
                            this.$nextTick(() => {
                                // document.querySelector("#settings-container").style.height = document.querySelector(".settings-area").getBoundingClientRect().height + "px";
                                // if (document.querySelector(".settings-area").scrollHeight > document.querySelector(".settings-area").getBoundingClientRect().height) {
                                //     document.querySelector("#settings-container").style.height = document.querySelector(".settings-area").scrollHeight + "px";
                                // }
                            });
                            clearInterval(showVisitCheck);
                        }
                    }, 100);
                });
            },

            handleMenuSelect(item) {

                if (!this.desktopResolution) return false;
                if (this.loading) {
                    return false;
                }
                this.activeTab = item;
                document.querySelector("body").scrollTop = 0;
                document.querySelector("#app").scrollTop = 0;

                this.loadStatisticsData();
            },

            setSearchOptions(params) {
                if (!this.desktopResolution) return false;
                this.filterQuery.start_date = params.start_date;
                this.filterQuery.end_date = params.end_date;
                this.filterQuery.timeline_type = params.timeline_type;
                this.filterQuery.checkedProjects = params.checkedProjects;
                this.filterQuery.checkedVideos = params.checkedVideos;
                this.filterQuery.checkedCountries = params.checkedFilters[0];
                this.filterQuery.checkedDomains = params.checkedFilters[1];
                this.filterQuery.checkedDevices = params.checkedFilters[2];

                this.loadStatisticsData();
            },

            /**
             * load statistics data
             */
            loadStatisticsData() {
                if (!this.desktopResolution) return false;
                this.filteredData = null;
                this.loading = true;

                let post_data = {
                    url: '/analytics/statistics',
                    data: {
                        ...this.filterQuery,
                        active: this.activeTab
                    }
                };

                this.$store.dispatch('post', {...post_data}).then(response => {
                    this.filteredData = response.data;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "~element-ui/lib/theme-chalk/input.css";
    @import "~element-ui/lib/theme-chalk/radio.css";
    @import "~element-ui/lib/theme-chalk/radio-group.css";
    @import "~element-ui/lib/theme-chalk/radio-button.css";
    @import "~element-ui/lib/theme-chalk/slider.css";

    .blurred{
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    .settings-area > #settings-container.el-container, .analytics-area > #settings-container.el-container, .dashboard-area > #settings-container.el-container{
        /*height: calc(100vh - 10px);*/
    }
</style>
<style lang="less" scoped src="../assets/less/custom/common.less"></style>
<style lang="less" src="../assets/less/custom/pages.less"></style>
<style lang="less" src="../assets/less/custom/settings.less"></style>
<style lang="less" src="../assets/less/custom/analytics.less"></style>
