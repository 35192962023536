<template>
    <el-card class="box-card h-100">
        <div slot="header" class="clearfix">
            <span class="size-20 main_color">{{ titleStr }}</span>
            <div class="size-16 main_color opacity-7">{{ subTitleStr }}: {{ dateLabel }}</div>
        </div>
        <div v-if="topVideos.length && topVideos.length > 0">
            <div v-for="(t_item, t_ind) in topVideos" :key="`top_videos_${t_ind}`" v-if="t_ind < 5" class="align-items-center video-history-lists m-top-4">
                <div class="country-title main_color size-16">
                    {{ t_item.title }}
                </div>
                <div class="history-progress m-right-2">
                    <el-progress :stroke-width="15" :percentage="getPercent(t_item.view_count)" :format="format" :color="progressColor"></el-progress>
                    <div class="history-views-part">{{ formatNumber(t_item.view_count) }}</div>
                </div>
            </div>
        </div>
        <div class="text-light-color m-top-4" v-else>
            No data to display this report.
        </div>
        <div class="m-top-4 left-see-all" v-if="topVideos.length && topVideos.length > 4">
            <a @click="showAllVideos = true">See All</a>
        </div>

        <!--  VIEW ALL Dialog -->
        <el-dialog
            :top="mobileCheck ? `10vh` : `30vh`"
            :visible.sync="showAllVideos"
            :width="mobileCheck ? `100%` : `700px`"
            center
            custom-class="pages-custom-dialog"
            :title="`${ titleStr }: ${dateLabel}`">
            <el-container class="video-list-modal">
                <div v-for="(t_item, t_ind) in topVideos" :key="`top_videos_${t_ind}`" class="align-items-center video-history-lists m-top-2">
                    <div class="country-title main_color size-16">
                        {{ t_item.title }}
                    </div>
                    <div class="history-progress m-right-2">
                        <el-progress :stroke-width="15" :percentage="getPercent(t_item.view_count)" :format="format" :color="progressColor"></el-progress>
                        <div class="history-views-part">{{ formatNumber(t_item.view_count) }}</div>
                    </div>
                </div>
            </el-container>
        </el-dialog>
    </el-card>
</template>

<script>
    export default {
        name: "TopCountries",
        props: {
            dateLabel: String,
            topVideos: Array,
            mobileCheck: Boolean,
            titleStr: {
                type: String,
                default: 'Top Countries'
            },
            subTitleStr: {
                type: String,
                default: 'Views'
            },
            progressColor: {
                type: String,
                default: '#D94D95'
            }
        },
        data: () => ({
            showAllVideos: false
        }),
        methods: {
            formatNumber(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },

            format(percentage) {
                return '';
            },
            getPercent(percent) {
                if (this.topVideos[0].view_count) {
                    return Math.round(((percent / this.topVideos[0].view_count) * 100) * 100) / 100;
                } else {
                    return 0;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~element-ui/lib/theme-chalk/progress.css";
    .text-gray-color {
        color: rgb(122, 127, 129);
        margin-top: 10px;
    }
    .left-see-all {
        padding-bottom: 4.75rem;
    }
</style>
