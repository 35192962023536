<template>
    <div class="analytics-overview ">
        <view-summary :filter-data="filterData" :date-label="date_range_label" :range-date="range_date"></view-summary>

        <view-trends :chart-data="chartData"></view-trends>

        <videos-history :date-label="date_range_label" :top-videos="filterData.top_videos"
                        :top24-videos="filterData.top_24_videos" :top24-charts="filterData.total_24_charts"></videos-history>

<!--        <social-media class="m-top-4" :filter-options="filterOptions" :filter-data="filterData" :mobile-check="mobileCheck"></social-media>-->
    </div>
</template>

<script>
    import moment from "moment";
    import ViewTrends from "./Items/ViewTrends";
    import VideosHistory from "./Items/VideosHistory";
    import SocialMedia from "./Items/SocialMedia";
    import ViewSummary from "./Items/ViewSummary";

    export default {
        name: "Overview",
        components: {ViewSummary, SocialMedia, VideosHistory, ViewTrends},
        props: {
            filterOptions: Object,
            filterData: Object,
            mobileCheck: Boolean
        },
        data: () => ({
            range_date: 'Last 30 Days',
            date_range_label: 'Last 30 Days',
            chartData: {
                labels: [],
                datasets: [],
            }
        }),
        mounted() {


            this.$nextTick(() => {
                this.date_range_label = this.$store.state.date_range_label[this.filterOptions.timeline_type];
                this.range_date = this.formatDate(this.filterOptions.start_date, this.filterOptions.end_date);
                this.fillData();
            });
        },

        methods: {
            fillData() {
                if (document.querySelector('#line-chart')) {
                    let gradient = document.querySelector('#line-chart').getContext("2d").createLinearGradient(0, 0, 0, 300);
                    gradient.addColorStop(0, 'rgba(90, 45, 219, 1)');
                    gradient.addColorStop(0.5, 'rgba(90, 45, 219, 0.5)');
                    gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');

                    let gradient1 = document.querySelector('#line-chart').getContext("2d").createLinearGradient(0, 0, 0, 300);
                    gradient1.addColorStop(0, 'rgba(86, 217, 255, 1)');
                    gradient1.addColorStop(0.5, 'rgba(86, 217, 255, 0.5)');
                    gradient1.addColorStop(1, 'rgba(255, 255, 255, 0.8)');

                    this.chartData = {
                        labels: this.filterData.trend.xLabel,
                        datasets: [
                            {
                                label: 'Impressions',
                                backgroundColor: gradient,
                                borderColor: 'rgba(90, 45, 219, 0.7)',
                                data: this.filterData.trend.impressions,
                                lineTension: 0.4,
                                fill: false,
                                pointHoverRadius: 3,
                                pointHoverBackgroundColor: 'rgba(90, 45, 219, 0.7)'
                            }, {
                                label: 'Views',
                                backgroundColor: gradient1,
                                borderColor: 'rgba(86, 217, 255, 0.7)',
                                data: this.filterData.trend.views,
                                lineTension: 0.4,
                                fill: false,
                                pointHoverRadius: 3,
                                pointHoverBackgroundColor: 'rgba(86, 217, 255, 0.7)'
                            }
                        ]
                    }
                }
            },
            formatDate(start, end){
                if(moment(start).format('MMM DD YYYY') === moment(end).format('MMM DD YYYY')){ // 06.02.2020 - 06.02.2020
                    return `${moment(start).format('MMM DD')}, ${moment(start).format('YYYY')}`; // Feb 06, 2020
                }
                if(moment(start).format('YYYY') === moment(end).format('YYYY')){ // 06.02.2020 - 20.02.2020 || 06.02.2020 - 20.03.2020 || 06.12.2019 - 06.01.2020
                    if(moment(start).format('MMM') === moment(end).format('MMM')){
                        return `${moment(start).format('MMM DD')} - ${moment(end).format('DD')}, ${moment(end).format('YYYY')}` // Feb 02 - 20, 2020
                    }
                    return `${moment(start).format('MMM DD')} - ${moment(end).format('MMM DD')}, ${moment(end).format('YYYY')}` // Feb 02 - Mar 20, 2020
                }else{
                    return `${moment(start).format('MMM DD')}, ${moment(start).format('YYYY')} - ${moment(end).format('MMM DD')}, ${moment(end).format('YYYY')}` // Dec 06, 2019 - Jan 01, 2020
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .analytics-overview {
        position: relative;
    }
</style>
