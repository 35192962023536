<template>
    <el-card class="condition-area" v-bind:class="{ scroll_auto: toggleOverflow }">
        <el-row class="condition-row align-items-center" :gutter="40">
<!--            <el-col class="condition-content condition_content_custom" :span="5" :xs="24">-->
<!--                <span class="size-14">Project:&nbsp;</span>-->
<!--                <el-button class="sel-video-button" @click="showProjectListPop()">-->
<!--                    <div class="selected-video-title size-14 opacity-6">-->
<!--                        <span v-if="projects.checkAll">All Projects</span>-->
<!--                        <span v-else>My Project...</span>-->
<!--                    </div>-->
<!--                    <i class="el-icon-arrow-up" v-if="projectPopOver"></i>-->
<!--                    <i class="el-icon-arrow-down" v-else></i>-->
<!--                </el-button>-->

<!--                <el-popover-->
<!--                    :popper-options="projectPopOptions"-->
<!--                    :visible-arrow="true"-->
<!--                    placement="right"-->
<!--                    popper-class="custom_popover pop_bottom_270 project-popover"-->
<!--                    trigger="click"-->
<!--                    v-model="projectPopOver">-->
<!--                    <div class="popover-content">-->
<!--                        <div class="form-group search-group">-->
<!--                            <el-input class="custom-el-input search-input" placeholder="Search Projects" v-model="projectSearch"></el-input>-->
<!--                            <img alt src="../../../assets/img/stage/icon_search.svg"/>-->
<!--                        </div>-->

<!--                        <el-checkbox class="custom-el-checkbox"-->
<!--                                     :indeterminate="projects.isIndeterminate" v-model="projects.checkAll" @change="handleCheckAllProject">-->
<!--                            Select all projects-->
<!--                        </el-checkbox>-->

<!--                        <div class="projects-list-group" v-if="filterProjectsList().length">-->
<!--                            <el-checkbox-group v-model="projects.checkedProjects" @change="handleCheckedProjectsChange">-->
<!--                                <el-checkbox v-for="(p_item, p_ind) in filterProjectsList()" :key="`p_ind_${p_ind}`" :label="p_item.id">-->
<!--                                    <div class="m-right-2">{{ p_item.title }}</div>-->
<!--                                </el-checkbox>-->
<!--                            </el-checkbox-group>-->
<!--                        </div>-->
<!--                        <div v-else>Not found matched project</div>-->

<!--                        <div class="m-top-3 w-100 text-center">-->
<!--                            <el-button round type="info" @click="closeProjectListsPop">-->
<!--                                Cancel-->
<!--                            </el-button>-->
<!--                            <el-button round type="primary" @click="getFilteredData">-->
<!--                                Apply-->
<!--                            </el-button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-popover>-->
<!--            </el-col>-->

            <el-col class="condition-content" :span="5" :xs="24">
                <span class="size-14">Media:&nbsp;</span>
                <el-button class="sel-video-button" @click="showVideoListsPop()">
                    <div class="selected-video-title size-14 opacity-6">
                        <span v-if="videos.checkAll">All Media</span>
                        <span v-else>My Video...</span>
                    </div>
                    <i class="el-icon-arrow-up" v-if="videoPopOver"></i>
                    <i class="el-icon-arrow-down" v-else></i>
                </el-button>

                <el-popover
                    :popper-options="projectPopOptions"
                    :visible-arrow="true"
                    placement="right"
                    popper-class="custom_popover pop_bottom_464 video-popover"
                    trigger="click"
                    v-model="videoPopOver">
                    <div class="popover-content">
                        <div class="form-group search-group">
                            <el-input
                                    class="custom-el-input search-input"
                                    placeholder="Search Videos"
                                    v-model="videoSearch">
                            </el-input>
                            <img alt src="../../../assets/img/stage/icon_search.svg"/>
                        </div>

                        <el-checkbox
                                :indeterminate="videos.isIndeterminate"
                                v-model="videos.checkAll"
                                @change="handleCheckAllVideo"
                                class="custom-el-checkbox"
                        >
                            Select all media
                        </el-checkbox>

                        <div class="videos-list-group" v-if="filterVideosList().length">
                            <el-checkbox-group v-model="videos.checkedVideos" @change="handleCheckedVideosChange">
                                <el-checkbox v-for="(v_item, v_ind) in filterVideosList()" :key="`video_ind_${v_ind}`" :label="v_item.id" >
                                    <!--<el-avatar
                                        :size="60"
                                        :src="v_item.thumbnail"
                                        :fit="scale-down"
                                        shape="square"></el-avatar> -->
                                    <div>
                                        <img :src="v_item.thumbnail"/>
                                        <span class="m-right-2 col-desc">{{ v_item.title | subStr }}</span>
                                    </div>

                                    <div class="latest-modified" align="right">
                                        <div>Last Modified</div> <!--NEMESIS CHANGE <div class="text-light-color">Last Modified</div> -->
<!--                                        <div>{{ v_item.last_modified }}</div>-->
                                        <div>{{changeDateFormate(v_item.updated_at)}}</div>
                                    </div>
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div v-else>Not found matched videos</div>

                        <div class="m-top-3 w-100 text-center">
                            <el-button round type="info" @click="closeVideoListsPop">
                                Cancel
                            </el-button>
                            <el-button round type="primary" @click="getFilteredData">
                                Apply
                            </el-button>
                        </div>
                    </div>
                </el-popover>
            </el-col>

            <el-col class="condition-content second" :span="7" :xs="24">
                <span class="size-14">Timeline:&nbsp;</span>
                <custom-date-range-picker
                    :conditionItem="conditionTime" :start-date="$store.state.start_date" :show-range="true"
                    v-on:change-date-range-picker-value="getFilteredData"
                    @click.native="closeOpenedDropdowns()" class="border-contentt"
                    parent-el=".analytics-area"></custom-date-range-picker>
            </el-col>

            <el-col class="condition-content" :span="7" :xs="24">
                <el-dropdown
                        :hide-on-click="false"
                        class="sel-video-button cursor-pointer border-contentt"
                        @command="handleCommandFilter"
                        placement="bottom"
                        trigger="click"
                >
                    <span class="el-dropdown-link selected-video-title" @click="closeOpenedDropdowns()">
                        Advanced Filters <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="custom_dropdown dropdown_bottom_200">
                        <el-dropdown-item v-for="(a_item, a_ind) in advanceFilters" :key="`a_item${a_ind}`"
                                          class="justify-content-center align-items-center" :command="a_item.index" >
                            <div @click="showFiltersInMobVersion(a_ind)">{{ a_item.name }}</div>
                            <i class="el-icon-arrow-right el-icon--right"></i>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-popover
                    :popper-options="projectPopOptions"
                    :visible-arrow="true"
                    placement="left"
                    popper-class="custom-search-box-popover filter-popup"
                    trigger="click"
                    v-for="r_i in 3"
                    v-model="advanceFilterPop[(r_i - 1)]" :key="`r_popover${(r_i - 1)}`">
                    <div class="arrow-right"></div>
                    <div class="popover-content">
                        <div class="form-group search-group">
                            <el-input class="custom-el-input search-input" placeholder="Search Locations" v-model="filtersSearch[(r_i - 1)]"></el-input>
                            <img alt src="../../../assets/img/stage/icon_search.svg"/>
                        </div>

                        <el-checkbox :indeterminate="filtersOpt.isIndeterminate[(r_i - 1)]" v-model="filtersOpt.checkAll[(r_i - 1)]" @change="handleCheckAllFilter($event, (r_i - 1))">
                            Select all {{ advanceFilters[(r_i - 1)].name }}
                        </el-checkbox>

                        <div class="filters-list-group" v-if="filtersRegionLists((r_i - 1)).length">
                            <el-checkbox-group v-model="filtersOpt.checkedFilters[(r_i - 1)]" @change="handleCheckedFilterChange($event, (r_i - 1))">
                                <el-checkbox v-for="(r_item, r_ind) in filtersRegionLists((r_i - 1))" :key="`f_ind_${r_ind}`" :label="r_i === 1 ? r_item.countryShortCode : r_item">
                                    {{ r_i === 1 ? r_item.countryName : r_item }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div v-else>Not found matched filters</div>

                        <div class="m-top-3 w-100 text-center">
                            <el-button round type="info" @click="closeFilterPop((r_i - 1))" class="mb-mob-2 ml-mob-0">
                                Cancel
                            </el-button>
                            <el-button round type="primary" @click="getFilteredData" class="mb-mob-2 ml-mob-0">
                                Apply
                            </el-button>
                        </div>
                    </div>
                </el-popover>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
    import CustomDateRangePicker from "../../Contacts/Items/CustomDateRangePicker";

    const cur_date = new Date();

    export default {
        name: "SearchOption",
        components: {CustomDateRangePicker},
        data: () => ({
            loadEditorHrefFirstTime:true,
            projectPopOver: false,
            projectPopOptions: {
                boundariesElement: ".condition-row"
            },
            projectList: [],
            projectSearch: "",
            projects: {
                checkAll: true,
                isIndeterminate: false,
                checkedProjects: [],
                oldChecked: []
            },
            videoPopOver: false,
            videoLists: [],
            videoSearch: "",
            videos: {
                checkAll: true,
                isIndeterminate: false,
                checkedVideos: [],
                oldChecked: []
            },
            conditionTime: {
                timeline_type: '30',
                start_date: `${cur_date.getFullYear()}-${cur_date.getUTCMonth()}-${cur_date.getDate()}`,
                end_date: `${cur_date.getFullYear()}-${cur_date.getUTCMonth() + 1}-${cur_date.getDate()}`,
            },
            advanceFilterPop: [false, false, false],
            advanceFilters: [
                {index: 'region', name: 'Region'}, {index: 'source', name: 'Source URL'}, {index: 'device', name: 'Device'},
            ],
            selFilter: '',
            filtersList: [[], [], ['Desktop', 'Mobile', 'Tablet']],
            filtersSearch: ['', '', ''],
            filtersOpt: {
                checkAll: [true, true, true],
                isIndeterminate: [false, false, false],
                checkedFilters: [[], [], []],
                oldChecked: [[], [], []]
            },
            toggleOverflow: true
        }),
        filters: {
            subStr: function(str) {
                if(str.length > 25){
                    return `${str.substring(0,25)}...`
                }else{
                    return str
                }
            }
        },
        beforeDestroy() {
            window.vEvent.stop('get-filtered-data', this.getFilteredData);
        },
        mounted() {
            window.vEvent.listen('get-filtered-data', this.getFilteredData);

            this.getProjectsList();
            this.$nextTick(() => {
                let USObj = {};
                let all_countries = JSON.parse(JSON.stringify(this.$store.state.countries));
                let USCountry = all_countries.filter(el => {
                    if (el.countryShortCode.toLowerCase() === 'us') {
                        USObj = el;
                        return true;
                    } else {
                        return false;
                    }
                });

                let ind = all_countries.indexOf(USObj);
                if (ind !== -1) all_countries.splice(ind, 1);

                this.filtersList[0] = USCountry.concat(JSON.parse(JSON.stringify(all_countries)));
                this.handleCheckAllFilter(true, 0);
                this.handleCheckAllFilter(true, 2);
            });
        },
        methods: {
            changeDateFormate(date){
                var date = date.split(" ");
                var parts = date[0].split('-');
                var mydate = new Date(parts[0], parts[1] - 1, parts[2]);

                return mydate.toDateString().substring(4);
            },
            getFilteredData() {
                setTimeout(() => { this.changeScrollAutoHeight(false, 100, 'filter-popup', 0)}, 10);
                this.projectPopOver = false;
                this.videoPopOver = false;
                this.advanceFilterPop = [false, false, false];

                let video_id = '';

                if (this.loadEditorHrefFirstTime) {
                    if (window.location.search != '') {
                        var pairs = window.location.search.substring(1).split("&");
                        var pair;

                        for (var i in pairs) {
                            if (pairs[i] === "") continue;
                            pair = pairs[i].split("=");

                            if (pair[0] == 'video') {
                                video_id = pair[1];
                            }
                        }
                    }
                }

                if (video_id != '') {
                    var $this=this;
                    this.videos.checkedVideos = [parseInt(video_id)]
                
                    this.loadEditorHrefFirstTime = false;

                    console.log(process.env.VUE_APP_BACKEND_URL + 'api/editor?id=' + video_id);

                    this.axios({
                        url: process.env.VUE_APP_BACKEND_URL + 'api/editor?id=' + video_id,
                        method: 'GET'
                    }).then(response => {
                        if (typeof response.data.video !== typeof undefined) {
                            let params = {
                                start_date: this.conditionTime.start_date,
                                end_date: this.conditionTime.end_date,
                                timeline_type: this.conditionTime.timeline_type,
                                checkedProjects: this.projects.checkedProjects,
                                checkedVideos: [response.data.video.id],
                                checkedFilters: this.filtersOpt.checkedFilters
                            };
                
                

                            window.vEvent.fire('set-analytics-search-query', params);
                        }
                    });
                } else {
                    let params = {
                        start_date: this.conditionTime.start_date,
                        end_date: this.conditionTime.end_date,
                        timeline_type: this.conditionTime.timeline_type,
                        checkedProjects: this.projects.checkedProjects,
                        checkedVideos: this.videos.checkedVideos,
                        checkedFilters: this.filtersOpt.checkedFilters
                    };

                    window.vEvent.fire('set-analytics-search-query', params);
                }
            },

            /**
             * Filter projects list
             */
            getProjectsList() {
                // let params = {
                //     url: `/projects`,
                //     config: null
                // };
                //
                // this.$store.dispatch("getData", {...params}).then(r => {
                //     this.projectList = r.data;
                //
                //     this.getVideosList();
                //
                //     this.handleCheckAllProject(this.projectList.length);
                // });
                // this.axios.get('/projects', { params: { v2: true } })
                //     .then(r => {
                //         this.projectList = r.data;
                //         console.log(r, 'asasasasasas');
                //         this.getVideosList();
                //         this.handleCheckAllProject(this.projectList.length);
                //     });

                this.getVideosList();
            },

            filterProjectsList() {
                let projects = this.projectList.filter(
                    data => (
                        !this.projectSearch || data.title.toLowerCase().includes(this.projectSearch.toLowerCase())
                    )
                );

                this.setCheckProjects(projects);

                return projects;
            },

            setCheckProjects(projects) {
                let checkedCount = this.projects.checkedProjects.length;
                this.projects.checkAll = checkedCount === projects.length;
                this.projects.isIndeterminate = checkedCount > 0 && checkedCount < projects.length;
            },

            showProjectListPop() {
                setTimeout(() => { this.changeScrollAutoHeight(this.projectPopOver, 100, 'project-popover', 0)}, 10);
                this.videoPopOver = false;
                this.advanceFilterPop = [false, false, false];
                this.setCheckProjects(this.filterProjectsList());
                this.projects.oldChecked = JSON.parse(JSON.stringify(this.projects.checkedProjects));
                this.projectPopOver = !this.projectPopOver;
                this.$forceUpdate();
            },

            closeProjectListsPop() {
                this.projects.checkedProjects = this.projects.oldChecked;
                this.projectPopOver = false;
                this.projectSearch = "";
                setTimeout(() => { this.changeScrollAutoHeight(this.projectPopOver, 100, 'project-popover', 0)}, 10);
                this.$forceUpdate();
            },

            handleCheckAllProject(val) {
                this.projects.checkedProjects = val ? this.filterProjectsList().map(el => {
                    return el.id;
                }) : [];
                this.projects.isIndeterminate = false;
                this.projects.checkAll = val;
            },

            handleCheckedProjectsChange(value) {
                let checkedCount = value.length;
                let projects = this.filterProjectsList();
                this.projects.checkAll = checkedCount === projects.length;
                this.projects.isIndeterminate = checkedCount > 0 && checkedCount < projects.length;
                this.$forceUpdate();
            },

            /**
             * Filters Video
             */
            getVideosList() {
                let params = {
                    url: `/contacts/get-all-videos`,
                    config: null
                };

                this.$store.dispatch("getData", {...params})
                .then(response => {
                    this.videoLists = response.data;
                    this.handleCheckAllVideo(this.videoLists.length);
                    this.getResourceDomains();
                });
            },

            filterVideosList() {
                let videos = this.videoLists.filter(
                    data => (
                        !this.videoSearch && (
                            this.projects.checkAll || (
                                !this.projects.checkAll && (this.projects.checkedProjects.indexOf(parseInt(data.project)) > -1)
                            )
                        )
                    ) || (
                        data.title.toLowerCase().includes(this.videoSearch.toLowerCase()) && (
                            this.projects.checkAll || (
                                !this.projects.checkAll && (this.projects.checkedProjects.indexOf(parseInt(data.project)) > -1)
                            )
                        )
                    )
                );

                this.setCheckVideos(videos);

                return videos;
            },

            setCheckVideos(videos) {
                let checkedCount = this.videos.checkedVideos.length;
                this.videos.checkAll = checkedCount === videos.length;
                this.videos.isIndeterminate = checkedCount > 0 && checkedCount < videos.length;
            },

            showVideoListsPop() {
                setTimeout(() => { this.changeScrollAutoHeight(this.videoPopOver, 100, 'video-popover', 0)}, 10);
                this.projectPopOver = false;
                this.advanceFilterPop = [false, false, false];
                this.setCheckVideos(this.filterVideosList());
                this.videos.oldChecked = JSON.parse(JSON.stringify(this.videos.checkedVideos));
                this.videoPopOver = !this.videoPopOver;
                this.$forceUpdate();
            },

            showFiltersInMobVersion(idx){
                setTimeout(() => {this.changeScrollAutoHeight(true, 100, 'filter-popup', idx)}, 100);
            },

            closeVideoListsPop() {
                this.videos.checkedVideos = this.videos.oldChecked;
                this.videoPopOver = false;
                this.videoSearch = "";
                setTimeout(() => { this.changeScrollAutoHeight(this.videoPopOver, 100, 'video-popover', 0)}, 10);
                this.$forceUpdate();
            },

            closeOpenedDropdowns(){
                 this.projectPopOver = false;
                 this.videoPopOver = false;
                 this.advanceFilterPop = [false, false, false];
                 setTimeout(() => { 
                     this.changeScrollAutoHeight(false, '', 'video-popover', 0);
                     this.changeScrollAutoHeight(false, '', 'project-popover', 0);
                }, 10);
            },

            handleCheckAllVideo(val) {
                this.videos.checkedVideos = val ? this.filterVideosList().map(el => {
                    return el.id;
                }) : [];
                this.videos.isIndeterminate = false;
                this.videos.checkAll = val;
            },

            handleCheckedVideosChange(value) {
                let checkedCount = value.length;
                let videos = this.filterVideosList();
                this.videos.checkAll = checkedCount === videos.length;
                this.videos.isIndeterminate = checkedCount > 0 && checkedCount < videos.length;
                this.$forceUpdate();
            },

            /**
             * Filter popover show
             * @param command
             */
            handleCommandFilter(command) {
                this.selFilter = command;
                if (command === 'region') {
                    if (this.advanceFilterPop[0]) {
                        this.filtersSearch[0] = '';
                    } else {
                        this.filtersOpt.oldChecked[0] = JSON.parse(JSON.stringify(this.filtersOpt.checkedFilters[0]));
                    }
                    this.advanceFilterPop[0] = !this.advanceFilterPop[0];
                    this.advanceFilterPop[1] = false;
                    this.advanceFilterPop[2] = false;
                } else if (command === 'source') {
                    if (this.advanceFilterPop[1]) {
                        this.filtersSearch[1] = '';
                    } else {
                        this.filtersOpt.oldChecked[1] = JSON.parse(JSON.stringify(this.filtersOpt.checkedFilters[1]));
                    }
                    this.advanceFilterPop[0] = false;
                    this.advanceFilterPop[1] = !this.advanceFilterPop[1];
                    this.advanceFilterPop[2] = false;
                } else if (command === 'device') {
                    if (this.advanceFilterPop[2]) {
                        this.filtersSearch[2] = '';
                    } else {
                        this.filtersOpt.oldChecked[2] = JSON.parse(JSON.stringify(this.filtersOpt.checkedFilters[2]));
                    }
                    this.advanceFilterPop[0] = false;
                    this.advanceFilterPop[1] = false;
                    this.advanceFilterPop[2] = !this.advanceFilterPop[2];
                } else {
                    this.advanceFilterPop[0] = false;
                    this.advanceFilterPop[1] = false;
                    this.advanceFilterPop[2] = false;
                    this.filtersSearch = ['', '', ''];
                }

                this.$forceUpdate();
            },

            closeFilterPop(ind) {
                setTimeout(() => { this.changeScrollAutoHeight(false, 100, 'filter-popup', ind)}, 10);
                this.filtersOpt.checkedFilters[ind] = this.filtersOpt.oldChecked[ind];
                this.advanceFilterPop[ind] = false;
                this.filtersSearch[ind] = '';

                this.$forceUpdate();
            },

            filtersRegionLists(ind) {
                let regions = this.filtersList[ind].filter(
                    data => (
                        !this.filtersSearch[ind] ||
                        (
                            ind === 0 ?
                                data.countryName.toLowerCase().includes(this.filtersSearch[ind].toLowerCase()) :
                                data.toLowerCase().includes(this.filtersSearch[ind].toLowerCase())
                        )
                    )
                );

                this.setCheckRegions(regions, ind);

                return regions;
            },

            setCheckRegions(regions, ind) {
                let checkedCount = this.filtersOpt.checkedFilters[ind].length ? this.filtersOpt.checkedFilters[ind].length : 0;
                this.filtersOpt.checkAll[ind] = checkedCount === regions.length;
                this.filtersOpt.isIndeterminate[ind] = checkedCount > 0 && checkedCount < regions.length;
            },

            handleCheckAllFilter(val, ind) {
                this.filtersOpt.checkedFilters[ind] = val ? this.filtersRegionLists(ind).map(el => {
                    return (ind === 0) ? el.countryShortCode : el;
                }) : [];

                this.filtersOpt.checkAll[ind] = val;
                this.filtersOpt.isIndeterminate[ind] = false;
            },

            handleCheckedFilterChange(value, ind) {
                let checkedCount = value.length;
                let regions = this.filtersRegionLists(ind);
                this.filtersOpt.checkAll[ind] = checkedCount === regions.length;
                this.filtersOpt.isIndeterminate[ind] = checkedCount > 0 && checkedCount < regions.length;
                this.$forceUpdate();
            },

            getResourceDomains() {
                let params = {
                    url: `/analytics/sources`,
                    config: null
                };

                this.$store.dispatch("getData", {...params})
                .then(response => {
                    this.filtersList[1] = response.data;

                    this.handleCheckAllFilter(true, 1);

                    this.getFilteredData();
                });
            },

            changeScrollAutoHeight(popover, h, changedContent, key){
                let popoverH = $(`.${changedContent}`)[key].offsetHeight;
                popover === true && window.innerWidth <= 414 ? $('.scroll_auto').css('height', `calc(${popoverH}px + ${h}px)`) : $('.scroll_auto').css('height', 'auto');
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "~element-ui/lib/theme-chalk/dropdown.css";
    @import "~element-ui/lib/theme-chalk/dropdown-item.css";
    @import "~element-ui/lib/theme-chalk/dropdown-menu.css";
    @import "~element-ui/lib/theme-chalk/avatar.css";


    .condition-content.second{
        flex-grow: 1;
        justify-content: center;
    }
</style>
