<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix align-items-center">
            <div class="text-left w-60">
                <span class="header-text main_color size-20">Realtime Activity</span>
                <div class="size-14 main_color opacity-7">Views: Last 24 Hours</div>
            </div>
            <!--
            <div class="w-40 text-right">
                <span class="header-text main_color size-20">{{ realtimeUsersCount }}</span>
                <div class="size-14 main_color opacity-7">Updating Live</div>
            </div>
            -->
        </div>
        <div class="w-100">
            <real-time-bar :chart-data="chartData"></real-time-bar>
            <div class="w-100 align-items-center text-light-color small">
                <div class="w-60 p-left-2">-24h</div>
                <div class="w-40 text-right">NOW</div>
            </div>
        </div>
        <div class="align-items-center m-top-2 videoHourView">
            <div class="w-40 text-light-color">
                Top Videos: 24 Hours
            </div>
            <div class="w-60 text-light-color">
                Views
            </div>
        </div>
        <div v-if="topVideos.length">
            <div v-for="(t_item, t_ind) in topVideos" :key="`top_videos_${t_ind}`" v-if="t_ind < 5" class="align-items-center video-prev-history-lists m-top-2">
                <div class="w-40 align-items-center">
                    <div class="history-thumbnail-part m-right-2">
                        <img alt="" :src="t_item.thumbnail" class="img-responsive"/>
                    </div>
                    <div class="video-title">
                        <a :href="$router.resolve(`/stage/videos/${t_item.video_key}`).href">{{ t_item.title }}</a>
                    </div>
                </div>
                <div class="w-60 align-items-center views-rt-wrapper">
                    <div class="history-views-part m-right-2">{{ formatNumber(t_item.view_count) }}</div>
                    <real-time-area :chart-data="getRealChartData(t_item.calc_data)"></real-time-area>
                </div>
            </div>
        </div>
        <div class="text-gray-color m-top-4" v-else>
            Not videos to show this report
        </div>
        <div class="m-top-4" v-if="topVideos.length && topVideos.length > 4 && showViewAll">
            <a @click="showAllVideos = true">See All</a>
        </div>
        <el-dialog
            v-if="showViewAll"
            :top="mobileCheck ? `10vh` : `30vh`"
            :visible.sync="showAllVideos"
            :width="mobileCheck ? `100%` : `700px`"
            center
            custom-class="pages-custom-dialog"
            :title="`VIEWS: Last 24 hours`">
            <el-container class="top-video-list-modal">
                <div v-for="(t_item, t_ind) in topVideos" :key="`top_videos_${t_ind}`" class="align-items-center video-prev-history-lists m-top-3">
                    <div class="w-60 align-items-center">
                        <div class="history-thumbnail-part m-right-2">
                            <img alt="" :src="t_item.thumbnail" class="img-responsive"/>
                        </div>
                        <div class="video-title">
                            <a :href="$router.resolve(`/stage/videos/${t_item.video_key}`).href">{{ t_item.title }}</a>
                        </div>
                    </div>
                    <div class="w-40 align-items-center">
                        <div class="history-views-part m-right-2">{{ formatNumber(t_item.view_count) }}</div>
                        <real-time-area :chart-data="getRealChartData(t_item.calc_data)"></real-time-area>
                    </div>
                </div>
            </el-container>
        </el-dialog>
    </el-card>
</template>

<script>
    import RealTimeBar from "./RealTimeBar";
    import RealTimeArea from "./RealTimeArea";

    export default {
        name: "TopRtVideos",
        components: {RealTimeArea, RealTimeBar},
        props: {
            topVideos: Array,
            mobileCheck: Boolean,
            totalCharts: Object,
            showViewAll: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            showAllVideos: false,
            chartData: {
                labels: [],
                datasets: [],
            },
            inRequest: false,
            realtimeUsersCount: 0,
            interval: null
        }),
        mounted() {
            this.$nextTick(() => {
                this.chartData = {
                    labels: this.totalCharts.xLabel,
                    datasets: [
                        {
                            label: 'Views',
                            backgroundColor: '#3392FF',
                            data: this.totalCharts.views
                        }
                    ]
                }
            });
            let $this = this;
            // this.realtimeUsers();
            // this.interval = setInterval(() => {
            //     let dashboard = this.$route && this.$route.name == 'Dashboard';
            //     if (dashboard)
            //         $this.realtimeUsers();
            //     else clearInterval(this.interval);
            // }, 180000);
        },
        methods: {
            formatNumber(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
            getRealChartData(item) {
                return {
                    labels: item.xLabel,
                    datasets: [
                        {
                            label: 'Views',
                            backgroundColor: 'rgba(220, 236, 255, 0.8)',
                            data: item.views,
                            lineTension: 0.2,
                            pointRadius: 1,
                            pointHoverRadius: 1,
                            borderColor: '#3392FF',
                            borderWidth: 1
                        }
                    ]
                };
            },
            realtimeUsers()
            {
                if (!this.inRequest) {
                    this.inRequest = true;
                    this.axios.post('/watch-session/realtime-users')
                        .then(r => {
                            this.inRequest = false;
                            this.realtimeUsersCount = r.data;
                        }).catch(err => {
                            console.log(err);
                            this.inRequest = false;
                        });
                }
            }
        }
    }
</script>

<style scoped lang="less">

    .header-text {
        font-weight: 500;
    }

    .text-gray-color {
        color: rgb(122, 127, 129);
    }
    .videoHourView{
        @media(max-width: 414px){
            justify-content: space-between;
            .w-60, .w-40{
                width: fit-content !important;
            }
        }
    }
</style>
