<template>
    <div class="analytics-engagement">
        <engagement-summary
            v-if="filterOptions.checkedVideos.length > 1"
            :date-label="date_range_label"
            :filter-data="filterData"
        />

        <engagement-trend-for-one
            v-if="filterOptions.checkedVideos.length === 1"
            :videoId='filterOptions.checkedVideos[0]'
            :chart-data="chartData"
            :date-label="date_range_label"
            :filter-data="filterData"
        />

        <interaction-summary
            :date-label="date_range_label"
            :filter-options="filterOptions"
            :filter-data="filterData"
        ></interaction-summary>

        <engagement-trends :chart-data="chartData" />

        <el-row :gutter="35"
            v-if="filterOptions.checkedVideos.length > 1"
            class="m-top-4 d-flex flex-wrap video-lists"
        >
            <el-col :span="12" :xs="24" class="px-xs-0">
                <top-videos-engagement
                    :date-label="date_range_label"
                    :top-videos="filterData.top_videos_engagement"
                    :mobile-check="mobileCheck"
                    title-str="Top Videos By Playthrough"
                    sub-title-str="Play Rate"
                ></top-videos-engagement>
            </el-col>
            <el-col :span="12" :xs="24" class="px-xs-0">
                <top-videos-engagement
                    progress-color="#B7DD51"
                    :date-label="date_range_label"
                    :top-videos="filterData.top_end_videos_engagement"
                    :mobile-check="mobileCheck"
                    title-str="Top Videos By Retention Power"
                    sub-title-str="Average Engagement"
                ></top-videos-engagement>
            </el-col>
        </el-row>

<!--        <social-media class="m-top-4" :filter-options="filterOptions" :filter-data="filterData" :mobile-check="mobileCheck"></social-media>-->
    </div>
</template>

<script>
import EngagementSummary from "./Items/EngagementSummary";
import InteractionSummary from "./Items/InteractionSummary";
import TopVideosEngagement from "./Items/TopVideosEngagement";
import SocialMedia from "./Items/SocialMedia";
import EngagementTrends from "./Items/EngagementTrends";
import EngagementTrendForOne from "./Items/EngagementTrendForOne";

export default {
    name: "Engagement",
    components: {
        EngagementTrends,
        SocialMedia,
        TopVideosEngagement,
        InteractionSummary,
        EngagementSummary,
        EngagementTrendForOne
    },
    props: {
        filterOptions: Object,
        filterData: Object,
        mobileCheck: Boolean
    },
    data: () => ({
        date_range_label: "Last 30 Days",
        chartData: {
            labels: [],
            datasets: []
        },
        backChartData: []
    }),
    beforeDestroy() {
        window.vEvent.stop("change-engagement-chart", this.changeChartData);
    },
    mounted() {
        this.$nextTick(() => {
            this.date_range_label = this.$store.state.date_range_label[
                this.filterOptions.timeline_type
            ];
            this.getFilteredData();
            window.vEvent.listen(
                "change-engagement-chart",
                this.changeChartData
            );
        });
    },
    methods: {
        changeChartData(index) {
            let chartData = [];
            this.$set(
                chartData,
                chartData.length,
                this.backChartData[index[0]]
            );
            this.$set(
                chartData,
                chartData.length,
                this.backChartData[index[1]]
            );
            this.$set(
                chartData,
                chartData.length,
                this.backChartData[index[2]]
            );
            this.setChartData(chartData);
        },
        getFilteredData() {
            if (document.querySelector("#line-chart")) {
                let gradient = document
                    .querySelector("#line-chart")
                    .getContext("2d")
                    .createLinearGradient(0, 0, 0, 350);
                gradient.addColorStop(0, "rgba(0, 114, 165, 1)");
                gradient.addColorStop(0.5, "rgba(0, 114, 165, 0.5)");
                gradient.addColorStop(1, "rgba(255, 255, 255, 1)");

                let gradient1 = document
                    .querySelector("#line-chart")
                    .getContext("2d")
                    .createLinearGradient(0, 0, 0, 350);
                gradient1.addColorStop(0, "rgba(86, 217, 254, 1)");
                gradient1.addColorStop(0.5, "rgba(86, 217, 254, 0.5)");
                gradient1.addColorStop(1, "rgba(255, 255, 255, 1)");

                let gradient2 = document
                    .querySelector("#line-chart")
                    .getContext("2d")
                    .createLinearGradient(0, 0, 0, 350);
                gradient2.addColorStop(0, "#00d34b");
                gradient2.addColorStop(0.5, "#00d34b50");
                gradient2.addColorStop(1, "rgba(255, 255, 255, 1)");

                let gradient3 = document
                    .querySelector("#line-chart")
                    .getContext("2d")
                    .createLinearGradient(0, 0, 0, 350);
                gradient3.addColorStop(0, "#AB7DF6");
                gradient3.addColorStop(0.5, "#AB7DF650");
                gradient3.addColorStop(1, "rgba(255, 255, 255, 1)");

                let gradient4 = document
                    .querySelector("#line-chart")
                    .getContext("2d")
                    .createLinearGradient(0, 0, 0, 350);
                gradient4.addColorStop(0, "#ae2e91");
                gradient4.addColorStop(0.5, "#ae2e9150");
                gradient4.addColorStop(1, "rgba(255, 255, 255, 1)");

                let gradient5 = document
                    .querySelector("#line-chart")
                    .getContext("2d")
                    .createLinearGradient(0, 0, 0, 350);
                gradient5.addColorStop(0, "#ebb9ff");
                gradient5.addColorStop(0.5, "#ebb9ff50");
                gradient5.addColorStop(1, "rgba(255, 255, 255, 1)");

                this.backChartData = [
                    {
                        label: "AVG. Engagement(%)",
                        backgroundColor: gradient,
                        borderColor: "rgba(0, 114, 165, 0.7)",
                        data: this.filterData.trend.engagement,
                        lineTension: 0.4,
                        fill: true,
                        pointHoverRadius: 3,
                        pointHoverBackgroundColor: "rgba(0, 114, 165, 0.7)"
                    },
                    {
                        label: "Watch Time(Minutes)",
                        backgroundColor: gradient1,
                        borderColor: "rgba(86, 217, 254, 0.7)",
                        data: this.filterData.trend.watch_time,
                        lineTension: 0.4,
                        fill: true,
                        pointHoverRadius: 3,
                        pointHoverBackgroundColor: "rgba(86, 217, 254, 0.7)"
                    },
                    {
                        label: "AVG. View Duration(Minutes)",
                        backgroundColor: gradient2,
                        borderColor: "#00d34be0",
                        data: this.filterData.trend.view_duration,
                        lineTension: 0.4,
                        fill: true,
                        pointHoverRadius: 3,
                        pointHoverBackgroundColor: "#00d34be0"
                    },
                    {
                        label: "ClickThrough",
                        backgroundColor: gradient3,
                        borderColor: "#AB7DF6e0",
                        data: this.filterData.trend.click_through,
                        lineTension: 0.4,
                        fill: true,
                        pointHoverRadius: 3,
                        pointHoverBackgroundColor: "#AB7DF6e0"
                    },
                    {
                        label: "Email Capture",
                        backgroundColor: gradient4,
                        borderColor: "#ae2e91e0",
                        data: this.filterData.trend.email_capture,
                        lineTension: 0.4,
                        fill: true,
                        pointHoverRadius: 3,
                        pointHoverBackgroundColor: "#ae2e91e0"
                    },
                    {
                        label: "Related Videos",
                        backgroundColor: gradient5,
                        borderColor: "#ebb9ffe0",
                        data: this.filterData.trend.related_videos,
                        lineTension: 0.4,
                        fill: true,
                        pointHoverRadius: 3,
                        pointHoverBackgroundColor: "#ebb9ffe0"
                    }
                ];

                this.changeChartData([0, 1, 3]);
            }
        },
        setChartData(data) {
            this.chartData = {
                labels: this.filterData.trend.xLabel,
                datasets: data
            };
        }
    }
};
</script>

<style lang="less" scoped>
.analytics-engagement {
    position: relative;
}
</style>
