<template>
    <div class='restrict-message' v-if='restricted'>
        <div class="restricted-body">
            <div class="restricted-title">
                <p class='text-center' v-if='!safariText.title'>FEATURE NOT AVAILABLE</p>
                <p class='text-center' v-else>{{ safariText.title }}</p>
            </div>
            <div class="divide"></div>
            <div class="icon">
                <img src="../../../static/img/restrictedIcon.svg">
            </div>
            <div class="restrict-description text-center">
                <p class='alert-title'>Heads Up!</p>
                <p class='alert-description' v-if='!safariText.message'>
                    <span class='section-name'>{{ section }}</span>
                    feature is not available on mobile.
                    To enjoy full experience of 4KPlayer, switch to desktop.
                </p>
                <p class='alert-description' v-else>
                    {{ safariText.message }}
                </p>
            </div>
            <div class="divide"></div>
            <div class="redirect-dashboard" v-if='!isSafari'>
                <router-link class="btn" to="/">Go Back to Dashboard</router-link>
            </div>
        </div>
    </div>
</template>

<style lang='less' scoped>  
.restrict-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 40000;
    background: rgba(0, 0, 0, 0.404);
    .restricted-body {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: max-content;
        z-index: 4000;
        background: #FFFFFF;
        padding: 15px 0;
        .icon {
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            width: max-content;
            padding: 15px 0;
        }
        .restricted-title p {
            line-height: 3;
        }
        .restrict-description {
            padding: 30px 30px 15px 30px;
            .alert-title {
                font-size: 20px;
            }
            .alert-description {
                font-size: 14px;
            }
            .section-name {
                font-size: 14px;
                font-weight: 500;
            }
        }
        .redirect-dashboard {
            text-align: center;
        }
        .redirect-dashboard .btn {
            background: #0DABD8;
            border-radius: 20px;
            color: #fff;
        }
        p {
            color: #21455E;
        }
    }
    .divide {
        height: 1px;
        background-color: #EBF9FA;
        margin: 5px 0 10px;
    }
}
</style>

<script>

export default {
    props: ['section', 'maxWidth', 'safariCheck'],
    data: () => ({
        windowWidth: null,
        restricted: false,
        isSafari: false,
        safariText: {
            title: '',
            message: ''
        }
    }),
    mounted()
    {
        window.addEventListener('resize', this.onResize);
        this.safariChecking();
        this.onResize();
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        safariChecking()
        {
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

            this.isSafari = isSafari;
        }
    },
    watch: {
        windowWidth(n, o)
        {
            console.log(n);
            if (n <= this.maxWidth) {
                this.restricted = true;
                this.$emit('desktopResolution', false);
            }
            else {
                let safariCheck = this.$props.safariCheck;
                if (safariCheck) {
                    if (this.isSafari) {
                        this.restricted = true;
                        this.$emit('desktopResolution', false);
                        this.safariText.title = "OH SNAP! YOU'VE HIT A LITTLE SNAG";
                        this.safariText.message = "SnapByte is not available on safari browser. To enjoy full experience of 4KPlayer, switch to chrome or firefox.";
                    } else {
                        this.restricted = false;
                        this.$emit('desktopResolution', true);
                    }
                } else {
                    this.restricted = false;
                    this.$emit('desktopResolution', true);
                }
            }
        }
    }
}
</script>