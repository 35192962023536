<template>
    <div class="slider-block w-100">
        <div class="thumbnail">
            <img alt :src="history.thumbnail" class="img-responsive" />
            <span class="video-duration">{{ getDurF(history.duration) }}</span>
        </div>
        <div class="history-slider" :id="`history-slider-${ind}`" v-if='mapData'>
            <el-slider v-model="mapData.history_value" :marks="mapData.watch_action"></el-slider>
            {{ setSliderStyle(history, ind) }}
        </div>
    </div>
</template>

<script>
export default {
    name: "WatchHistoryHeatmap",
    props: {
        history: Object,
        ind: [Number, String],
        singleVideo: Boolean
    },
    data: () => ({
      mapData: null
    }),
    mounted() {
      this.mapData = this.history;
      this.adaptiveDuration();
    },
    methods: {
        getDurF(s) {
            let d = new Date(s * 1000);
            let secs = d.getUTCSeconds();
            let minutes = d.getUTCMinutes();

            if (d.getUTCHours() > 0) {
                return (
                    d.getUTCHours() +
                    ":" +
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            } else {
                return (
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            }
        },
        setSliderStyle(history, ind) {
            setTimeout(() => {
                Object.keys(history.watch_action).forEach((key, index) => {
                    let el = document.querySelectorAll(
                        `#history-slider-${ind} .el-slider .el-slider__stop.el-slider__marks-stop`
                    )[index];
                    if (el) {
                        Object.keys(history.watch_action[key].styles).forEach(
                            c_key => {
                                el.style[c_key] =
                                    history.watch_action[key].styles[c_key];
                            }
                        );

                        if(history.watch_action[key].styles.border == "1px dotted black"){
                            let backendUrl = this.backendUrl;
                            el.innerHTML='<div class="pin-point tooltip"><img src="'+backendUrl+'img/pin.png" style="height: 17px;width: 15px;"><span class="tooltiptext">'+history.watch_action[key].value+'</span></div>';
                        }
                        else{
                            el.className = 'el-slider__stop el-slider__marks-stop tooltipmap';
                            let watchedTimes = Math.round(history.watch_action[key].value);
                            if (watchedTimes < 1) watchedTimes = 'Skipped';
                            else if (watchedTimes > 4) watchedTimes = '>4X';
                            else watchedTimes = `${watchedTimes} X`;
                            el.innerHTML = '<span class="tooltiptextmap">'+watchedTimes+'</span>'
                        }
                    }
                });
            }, 500);
        },
        adaptiveDuration()
        {
          let data  = this.mapData.watch_action,
              keys  = Object.keys(data),
              screenWidth = window.screen.availWidth,
              allowedLength = screenWidth > 960 ? 16 : 4;

          if (keys.length > allowedLength) {

            let gap = Math.round(keys.length / allowedLength);
            for (let i = 0; i < keys.length; i++) {
              if (i % gap != 0) {
                if (data[keys[i]]) {
                  data[keys[i]].label = '';
                }
              }
            }

            return this.mapData.watch_action = data;
          } else {
            return this.mapData.watch_action = data;
          }
        }
    },
    watch: {
      history(n, o)
      {
        this.mapData = n;
        this.adaptiveDuration();
      }
    }
};
</script>

<style lang="less">
.el-slider__stop {
    border-radius: 0% !important;
}
.slider-block.w-100 {
    // width: 83%!important;
    // margin-left: 7%;
    // margin-top: 42px;
}
.el-slider__runway {
    background-color: #fff !important;
}
.el-slider__bar {
    background-color: #fff !important;
}
.el-slider__marks-text {
    // margin-top: 68px;
}
.history-slider {
    width: 100%;
}
.el-slider__marks-text:last-child {
    margin-left: 0px !important;
}
.pin-point {
    height: 17px;
    width: 15px;
    position: relative;
    bottom: 31%;
    right: 7.5px;
}

.el-slider__button-wrapper {
    display: none;
}

.el-tooltip__popper {
    display: none;
}

.tooltipmap {
  //position: relative;
  display: inline-block;
  //border-bottom: 1px dotted black;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltipmap .tooltiptextmap {
  visibility: hidden;
  width: 90px;
  height: 17px;
  /*background: #011A22 0% 0% no-repeat padding-box;*/
  color: #2c3e50;
  text-align: center;
  font-size: 13px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 28px;
  /*margin-left: 20px;*/
  /*opacity: 0.3;*/
  letter-spacing: 0px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltipmap:hover .tooltiptextmap {
  visibility: visible;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: rgb(255, 255, 255);
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #C9EDF8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #C9EDF8;
}
</style>
