<template>
    <el-card class="box-card engagement-trends m-top-4">
        <div slot="header" class="clearfix">
            <span class="header-bold">Engagement Trends</span>
        </div>
        <div class="legend-dropdown align-items-center" id="legend-dropdown">
            <el-select
                :value="checkedLegend[0]"
                class="w-100 custom-el-select"
                popper-class="checkbox-select custom_select_pop with_arrow_190"
                v-model="checkedLegend[0]"
                @change="changeLegend(0)"
                placem
            >
                <el-option
                    v-for="item in command_ary"
                    :key="`check_legend_0_${item.value}`"
                    :label="item.label"
                    :value="item.value"
                    :disabled="checkedLegend[1] === item.value || checkedLegend[2] === item.value"
                >
                    <div class="check-icon">
                        <i v-if="checkedLegend[0] === item.value" class="el-icon-check"></i>
                    </div>
                    <span class="legend-label">{{ item.label }}</span>
                </el-option>
            </el-select>
            <el-select
                :value="checkedLegend[1]"
                class="w-100 custom-el-select"
                popper-class="checkbox-select custom_select_pop with_arrow_190"
                v-model="checkedLegend[1]"
                @change="changeLegend(1)"
            >
                <el-option
                    v-for="item in command_ary"
                    :key="`check_legend_1_${item.value}`"
                    :label="item.label"
                    :value="item.value"
                    :disabled="checkedLegend[0] === item.value || checkedLegend[2] === item.value"
                >
                    <div class="check-icon">
                        <i v-if="checkedLegend[1] === item.value" class="el-icon-check"></i>
                    </div>
                    <span class="legend-label">{{ item.label }}</span>
                </el-option>
            </el-select>
            <el-select
                :value="checkedLegend[2]"
                class="w-100 custom-el-select"
                popper-class="checkbox-select custom_select_pop with_arrow_190"
                v-model="checkedLegend[2]"
                @change="changeLegend(2)"
            >
                <el-option
                    v-for="item in command_ary"
                    :key="`check_legend_2_${item.value}`"
                    :label="item.label"
                    :value="item.value"
                    :disabled="checkedLegend[0] === item.value || checkedLegend[1] === item.value"
                >
                    <div class="check-icon">
                        <i v-if="checkedLegend[2] === item.value" class="el-icon-check"></i>
                    </div>
                    <span class="legend-label">{{ item.label }}</span>
                </el-option>
            </el-select>
        </div>
        <div class="trend-chart-area">
            <line-chart :chart-data="chartData" :options="options"></line-chart>
        </div>
    </el-card>
</template>

<script>
import LineChart from "../../../utils/Chart/LineChart";
import { customTooltip } from "../../../utils/Chart/Tooltip";

export default {
    name: "EngagementTrends",
    components: { LineChart },
    props: {
        chartData: {
            type: Object,
            default: {
                labels: [],
                datasets: []
            }
        }
    },
    data: () => ({
        options: {
            responsive: true,
            maintainAspectRatio: false,
            onResize: (chart)=>{ 
                if(window.innerWidth <= 414){
                    chart.scales['x-axis-0'].options.ticks.maxTicksLimit = 2
                }
            },
            events: ["mousemove"], // this is needed, otherwise onHover is not fired
            onHover: (event, chartElement) => {
                event.target.style.cursor = chartElement[0]
                    ? "pointer"
                    : "default";
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: false,
                mode: "point",
                intersect: true,
                custom: customTooltip
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawTicks: false,
                            color: "#EAF0F4",
                            zeroLineColor: "#EAF0F4"
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 10
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawTicks: false,
                            color: "transparent"
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 7,
                            lineHeight: "normal",
                            maxRotation: 0,
                            padding: 10
                        }
                    }
                ]
            }
        },
        command_ary: [
            { value: 0, label: "AVG. Engagement", color: "#0072A5" },
            { value: 1, label: "Watchtime", color: "#56D9FE" },
            { value: 2, label: "AVG. View Duration", color: "#00d34b" },
            { value: 3, label: "Clickthrough", color: "#AB7DF6" },
            { value: 4, label: "Email Capture", color: "#ae2e91" },
            { value: 5, label: "Related Videos", color: "#ebb9ff" }
        ],
        checkedLegend: [0, 1, 3]
    }),
    mounted() {
        this.$nextTick(() => {
            this.changeLegend(0);
            this.changeLegend(1);
            this.changeLegend(2);
        });
    },
    methods: {
        changeLegend(flag) {
            let checkedLeg = this.checkedLegend[flag];
            let sel_item = this.command_ary[checkedLeg];
            if (document.querySelector("#legend-dropdown")) {
                let input_div = document.querySelectorAll(
                    `#legend-dropdown .custom-el-select`
                )[flag].childNodes;

                input_div.forEach(el => {
                    if (el.className) {
                        if (el.className.split("el-input").length > 1) {
                            if (
                                document.querySelector(
                                    `#el-select-shows-area-${flag}`
                                )
                            ) {
                                document
                                    .getElementById(
                                        `el-select-shows-area-${flag}`
                                    )
                                    .remove();
                            }
                            if (
                                document.querySelector(
                                    `#el-select-shows-bg-label-${flag}`
                                )
                            ) {
                                document
                                    .getElementById(
                                        `el-select-shows-bg-label-${flag}`
                                    )
                                    .remove();
                            }

                            let div1 = document.createElement("div");
                            div1.setAttribute(
                                "id",
                                `el-select-shows-area-${flag}`
                            );
                            div1.style.width = "100%";
                            div1.className = "el-select-shows-area";

                            let div = document.createElement("div");
                            div.setAttribute(
                                "id",
                                `el-select-shows-bg-label-${flag}`
                            );
                            div.style.backgroundColor = sel_item.color;
                            div.style.opacity = 0.1;
                            div.style.width = "100%";
                            div.className = "el-select-shows-bg";

                            let checkedDiv = document.createElement("div");
                            checkedDiv.className = "sel-checked-label";
                            checkedDiv.style.backgroundColor = sel_item.color;
                            checkedDiv.innerHTML =
                                '<svg id="Good_checkmark" data-name="Good checkmark" xmlns="http://www.w3.org/2000/svg" width="8.559" height="6.098" viewBox="0 0 8.559 6.098">\n' +
                                '<g id="Group_4357" data-name="Group 4357">\n' +
                                '<path id="Path_4291" data-name="Path 4291" d="M8.434,68.119a.437.437,0,0,0-.605,0L2.7,73.094.73,71.182a.437.437,0,0,0-.605,0,.406.406,0,0,0,0,.587L2.4,73.974a.437.437,0,0,0,.605,0l5.43-5.268A.407.407,0,0,0,8.434,68.119Z" transform="translate(0 -67.997)" fill="#fff"/>\n' +
                                "</g>\n" +
                                "</svg>";
                            let labelDiv = document.createElement("div");
                            labelDiv.className = "sel-label-str";
                            labelDiv.innerHTML = sel_item.label;
                            labelDiv.style.marginLeft = "5px";

                            div1.appendChild(div);
                            div1.appendChild(checkedDiv);
                            div1.appendChild(labelDiv);
                            el.appendChild(div1);
                        }
                    }
                });
            }

            window.vEvent.fire("change-engagement-chart", this.checkedLegend);
        }
    }
};
</script>

<style scoped lang="less">
.engagement-trends {
    .trend-chart-area {
        height: 400px;
    }

    .header-bold {
        font-weight: 500;
    }

    .legend-dropdown {
        padding: 0 0 12px;

        &::-webkit-scrollbar {
            height: 4px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(201, 237, 248, 0.5);
            border-radius: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #c9edf8;
            border-radius: 7px;
            outline: 1px solid slategrey;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #c9edf8;
        }
        @media (max-width: 414px) {
            overflow-y: auto;
        }
    }
}
</style>
