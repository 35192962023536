<template>
    <div class="settings-right-header-footer">
        <div class="header-footer-left-side">
            <slot name="title"></slot>
        </div>
        <div class="header-footer-right-side">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RightHeaderFooter"
    };
</script>

<style lang="less" scoped>
    @tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
    @mobile: ~"only screen and (min-width: 300px) and (max-width: 414px)";
    .settings-right-header-footer {
        width: 100%;
        display: flex;
        align-items: center;

        &.header-items {
            padding: 0 0 2rem 0;
        }

        &.footer-items {
            padding: 2rem 0;
        }

        .header-footer-left-side {
            text-align: left;
            width: 40%;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 24px;
            color: var(--textThemeColor);
            @media @mobile {
                font-size: 18px;
                width: 60%;
//                width: fit-content !important;
            }
        }

        .header-footer-right-side {
            text-align: right;
            width: 60%;
            padding-right: 20px; //NEMESIS ADD
        }
    }
</style>
