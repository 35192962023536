<template>
    <el-container class="justify-content-center">
        <el-card class="box-card view-summary m-top-4">
            <div slot="header" class="clearfix">
                <span class="header-bold">Engagement Summary</span>
            </div>

            <summary-section :show-column="3">
                <template v-slot:second-head>
                    <el-popover
                        ref="avgEngagementPop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top"
                        :content="popoverContent.avgEngagemnt"
                    ></el-popover>AVG. ENGAGEMENT
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:avgEngagementPop
                        v-if="help_hover"
                        alt
                        @mouseout="help_hover = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:avgEngagementPop
                        v-else
                        alt
                        @mouseover="help_hover = true"
                    />
                </template>
                <template v-slot:second-body>
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.avg_engagement.today"
                        :filter-val-yesterday="filterData.summary.avg_engagement.yesterday"
                        :filter-val-curr="filterData.summary.avg_engagement.total"
                        :filter-val-prev="filterData.summary.avg_engagement.prev"
                        summary-unit="%"
                    ></summary-body>
                </template>

                <template v-slot:third-head>
                    <el-popover
                        ref="watchPop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_220x62"
                        :content="popoverContent.watchTime"
                    ></el-popover>WATCH TIME
                    <span class="engagement-minutes">(Minutes)</span>
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:watchPop
                        v-if="help_hover1"
                        alt
                        @mouseout="help_hover1 = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:watchPop
                        v-else
                        alt
                        @mouseover="help_hover1 = true"
                    />
                </template>
                <template v-slot:third-body>
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="Math.round((filterData.summary.watch.today / 60) * 100) / 100"
                        :filter-val-yesterday="Math.round((filterData.summary.watch.yesterday / 60) * 100) / 100"
                        :filter-val-curr="Math.round(filterData.summary.watch.total / 60)"
                        :filter-val-prev="Math.round(filterData.summary.watch.prev / 60)"
                    ></summary-body>
                </template>

                <template v-slot:fourth-head>
                    <el-popover
                        ref="avgDurationPop"
                        placement="top"
                        trigger="hover"
                        popper-class="custom_popover pop_top_250x62"
                        :content="popoverContent.avgViewDuration"
                    ></el-popover>AVG. VIEW DURATION
                    <img
                        src="../../../assets/img/analytics/noun-info-hover.svg"
                        v-popover:avgDurationPop
                        v-if="help_hover2"
                        alt
                        @mouseout="help_hover2 = false"
                    />
                    <img
                        src="../../../assets/img/analytics/noun-info.svg"
                        v-popover:avgDurationPop
                        v-else
                        alt
                        @mouseover="help_hover2 = true"
                    />
                </template>
                <template v-slot:fourth-body>
                    <summary-body
                        :date-label="dateLabel"
                        :filter-val-today="filterData.summary.avg_duration.today"
                        :filter-val-yesterday="filterData.summary.avg_duration.yesterday"
                        :filter-val-curr="filterData.summary.avg_duration.total"
                        :filter-val-prev="filterData.summary.avg_duration.prev"
                        :time-show="true"
                    ></summary-body>
                </template>
            </summary-section>
        </el-card>
    </el-container>
</template>

<script>
import { ENGAGEMENT_SUMMARY_POPOVER_CONTENT } from "../../../constants";
import SummarySection from "./SummarySection";
import SummaryBody from "./SummaryBody";

export default {
    name: "EngagementSummary",
    components: { SummaryBody, SummarySection },
    props: {
        filterData: Object,
        dateLabel: String
    },
    data: () => ({
        help_hover: false,
        help_hover1: false,
        help_hover2: false,
        popoverContent: ENGAGEMENT_SUMMARY_POPOVER_CONTENT
    })
};
</script>

<style scoped lang="less">
.view-summary {
    width: 100%;

    .header-bold {
        font-weight: 500;
    }

    &.three-column {
        width: 80%;
    }

    .engagement-minutes {
        color: rgba(33, 69, 94, 0.7);
        margin-left: 5px;
    }
}
</style>
